@charset "UTF-8";
/* Variables */
/* Mixins */
.sequoia .sequoia-search, .sequoia .sequoia-tree li {
  padding: 10px 15px; }

.sequoia .sequoia-modal-container .sequoia-modal-title a.sequoia-modal-close, .sequoia .sequoia-button, .sequoia .sequoia-tree .sequoia-item-title, .sequoia input[type="text"] {
  padding: 6px 12px; }

.sequoia .sequoia-modal-container .sequoia-modal-title a.sequoia-modal-close, .sequoia .sequoia-button {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px; }
  .sequoia .sequoia-modal-container .sequoia-modal-title a.sequoia-modal-close:hover, .sequoia .sequoia-button:hover, .sequoia .sequoia-modal-container .sequoia-modal-title a.sequoia-modal-close:active, .sequoia .sequoia-button:active, .sequoia .sequoia-modal-container .sequoia-modal-title a.sequoia-modal-close:focus, .sequoia .sequoia-button:focus {
    text-decoration: none; }

.sequoia .sequoia-search .sequoia-actions ul, .sequoia .sequoia-breadcrumbs {
  padding-left: 0;
  margin-left: -5px;
  list-style: none; }
  .sequoia .sequoia-search .sequoia-actions ul li, .sequoia .sequoia-breadcrumbs li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px; }

/* Sequoia */
#sequoia-tree {
  position: relative; }

.sequoia {
  width: 100%;
  /* Modal */
  /* Search */
  /* Breadcrumbs */
  /* Tree */
  /* Buttons */
  /**
   * Animations
   */ }
  .sequoia .sequoia-up-one-level {
    padding: 5px 10px; }
  .sequoia .sequoia-modal-container {
    position: relative;
    height: 100%;
    width: 100%; }
    .sequoia .sequoia-modal-container .sequoia-overlay {
      position: fixed;
      background: rgba(255, 255, 255, 0.64);
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 9999; }
    .sequoia .sequoia-modal-container .sequoia-modal-title {
      border-bottom: 1px solid #d0d5d5;
      padding-bottom: 10px;
      width: 100%; }
      .sequoia .sequoia-modal-container .sequoia-modal-title:before, .sequoia .sequoia-modal-container .sequoia-modal-title:after {
        content: " ";
        display: table; }
      .sequoia .sequoia-modal-container .sequoia-modal-title:after {
        clear: both; }
      .sequoia .sequoia-modal-container .sequoia-modal-title h4 {
        padding-left: 15px; }
      .sequoia .sequoia-modal-container .sequoia-modal-title a.sequoia-modal-close {
        font-size: 18px;
        font-weight: bold; }
    .sequoia .sequoia-modal-container .sequoia-modal {
      background-color: #fff;
      border: 1px solid #ebeded;
      border-radius: 3px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
      left: 5%;
      margin-left: -2.5%;
      position: fixed;
      top: 10%;
      width: 90%;
      z-index: 9999; }
      @media (min-width: 786px) {
        .sequoia .sequoia-modal-container .sequoia-modal {
          left: 50%;
          top: 50%;
          margin-left: -380px;
          margin-top: -260px;
          width: 640px; } }
  .sequoia .sequoia-notification {
    text-align: center;
    width: 100%; }
    .sequoia .sequoia-notification p {
      padding: 3px 10px;
      background-color: #fbe9d9;
      border: 1px solid #f6ceab;
      border-radius: 3px;
      color: #e67e22;
      font-size: 14px;
      margin: 0px 10px; }
      .sequoia .sequoia-notification p:before, .sequoia .sequoia-notification p:after {
        content: " ";
        display: table; }
      .sequoia .sequoia-notification p:after {
        clear: both; }
      .sequoia .sequoia-notification p span {
        margin-top: 4px; }
      .sequoia .sequoia-notification p a {
        color: #e67e22;
        margin-top: -5px; }
  .sequoia .sequoia-search:before, .sequoia .sequoia-search:after {
    content: " ";
    display: table; }
  .sequoia .sequoia-search:after {
    clear: both; }
  @media (min-width: 786px) {
    .sequoia .sequoia-search .sequoia-search-form,
    .sequoia .sequoia-search .sequoia-actions,
    .sequoia .sequoia-search input[type="text"],
    .sequoia .sequoia-search .sequoia-search-form a {
      float: left; } }
  .sequoia .sequoia-search .sequoia-search-form {
    display: block; }
    .sequoia .sequoia-search .sequoia-search-form:before, .sequoia .sequoia-search .sequoia-search-form:after {
      content: " ";
      display: table; }
    .sequoia .sequoia-search .sequoia-search-form:after {
      clear: both; }
    .sequoia .sequoia-search .sequoia-search-form a {
      margin-left: 10px; }
    @media (min-width: 786px) {
      .sequoia .sequoia-search .sequoia-search-form {
        width: 80%; } }
    .sequoia .sequoia-search .sequoia-search-form input[type="text"]:disabled {
      background-color: #ebeded;
      cursor: not-allowed; }
  .sequoia .sequoia-search .sequoia-actions {
    display: block; }
    @media (min-width: 786px) {
      .sequoia .sequoia-search .sequoia-actions {
        width: 20%; } }
  .sequoia .sequoia-breadcrumbs {
    margin-left: 10px; }
    .sequoia .sequoia-breadcrumbs li {
      padding-right: 0; }
      .sequoia .sequoia-breadcrumbs li:after {
        content: ' »'; }
      .sequoia .sequoia-breadcrumbs li:last-child:after {
        content: ''; }
  .sequoia .sequoia-tree {
    list-style-type: none;
    margin-left: -10px;
    padding-left: 0; }
    .sequoia .sequoia-tree li {
      border-bottom: 1px solid #d0d5d5; }
      .sequoia .sequoia-tree li:before, .sequoia .sequoia-tree li:after {
        content: " ";
        display: table; }
      .sequoia .sequoia-tree li:after {
        clear: both; }
    .sequoia .sequoia-tree .sequoia-item-actions {
      margin-left: 10px; }
    @media (min-width: 786px) {
      .sequoia .sequoia-tree .sequoia-item-title,
      .sequoia .sequoia-tree .sequoia-item-actions {
        margin-left: 0;
        float: left; } }
    .sequoia .sequoia-tree .sequoia-item-title {
      display: block; }
      @media (min-width: 786px) {
        .sequoia .sequoia-tree .sequoia-item-title {
          width: 80%; } }
    .sequoia .sequoia-tree .sequoia-item-actions {
      display: block; }
      @media (min-width: 786px) {
        .sequoia .sequoia-tree .sequoia-item-actions {
          width: 20%; } }
  .sequoia .sequoia-button.sequoia-button-primary {
    background-color: #1dd1ae;
    color: #fff; }
    .sequoia .sequoia-button.sequoia-button-primary:hover {
      background-color: #17a489;
      border-color: #17a489; }
  .sequoia .sequoia-button.sequoia-button-success {
    background-color: #2ecc71;
    color: #fff; }
    .sequoia .sequoia-button.sequoia-button-success:hover {
      background-color: #25a25a;
      border-color: #25a25a; }
  .sequoia .sequoia-button.sequoia-button-danger {
    background-color: #e74c3c;
    color: #fff; }
    .sequoia .sequoia-button.sequoia-button-danger:hover {
      background-color: #d62c1a;
      border-color: #d62c1a; }
  .sequoia .sequoia-button.sequoia-button-info {
    background-color: #3498db;
    color: #fff; }
    .sequoia .sequoia-button.sequoia-button-info:hover {
      background-color: #217dbb;
      border-color: #217dbb; }
  .sequoia .sequoia-button.sequoia-button-warning {
    background-color: #e67e22;
    color: #fff; }
    .sequoia .sequoia-button.sequoia-button-warning:hover {
      background-color: #bf6516;
      border-color: #bf6516; }
  .sequoia .sequoia-button.sequoia-button-default {
    background-color: #7f8c8d;
    color: #fff; }
    .sequoia .sequoia-button.sequoia-button-default:hover {
      background-color: #667273;
      border-color: #667273; }
  .sequoia input[type="text"] {
    border: 1px solid #d0d5d5;
    border-radius: 4px;
    width: 90%; }
  .sequoia .help-text {
    font-size: 11px;
    color: #7f8c8d;
    margin: 5px 0px; }
    .sequoia .help-text.has-error {
      color: #e74c3c; }
    .sequoia .help-text .mute {
      color: #9aa4a5; }
  .sequoia .as-sortable-dragging {
    background-color: #e1f0fa;
    border: 1px dotted #b6daf2; }
  .sequoia .sequoia-animate-repeat.ng-enter, .sequoia .sequoia-animate-repeat.ng-leave {
    transition: all 0 cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .sequoia .sequoia-animate-repeat.ng-enter {
    transform: translateX(-60px);
    transition-duration: 250ms;
    opacity: 0; }
  .sequoia .sequoia-animate-repeat.ng-enter-active {
    transform: translateX(0);
    opacity: 1; }
  .sequoia .sequoia-animate-repeat.ng-leave {
    transform: translateX(0);
    transition-duration: 250ms;
    opacity: 1; }
  .sequoia .sequoia-animate-repeat.ng-leave-active {
    transform: translateX(-60px);
    opacity: 0; }
  .sequoia .sequoia-animate-repeat.ng-hide-add {
    transform: translateX(0);
    transition-duration: 250ms;
    opacity: 1; }
  .sequoia .sequoia-animate-repeat.ng-hide-add.ng-hide-add-active {
    transform: translateX(-60px);
    opacity: 0; }
  .sequoia .sequoia-animate-repeat.ng-hide-remove {
    transform: translateX(-60px);
    transition-duration: 250ms;
    display: block !important;
    opacity: 0; }
  .sequoia .sequoia-animate-repeat.ng-hide-remove.ng-hide-remove-active {
    transform: translateX(0);
    opacity: 1; }
