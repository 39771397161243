.bg-primary { background-color: var(--color-primary) !important; }
.bg-primary-100 { background-color: var(--color-primary-100) !important; }
.bg-primary-200 { background-color: var(--color-primary-200) !important; }
.bg-primary-300 { background-color: var(--color-primary-300) !important; }
.bg-primary-400 { background-color: var(--color-primary-400) !important; }
.bg-primary-500 { background-color: var(--color-primary-500) !important; }
.bg-primary-600 { background-color: var(--color-primary-600) !important; }
.bg-primary-700 { background-color: var(--color-primary-700) !important; }
.bg-primary-800 { background-color: var(--color-primary-800) !important; }
.bg-primary-900 { background-color: var(--color-primary-900) !important; }
.bg-danger { background-color: var(--color-danger) !important; }
.bg-danger-100 { background-color: var(--color-danger-100) !important; }
.bg-danger-200 { background-color: var(--color-danger-200) !important; }
.bg-danger-300 { background-color: var(--color-danger-300) !important; }
.bg-danger-400 { background-color: var(--color-danger-400) !important; }
.bg-danger-500 { background-color: var(--color-danger-500) !important; }
.bg-danger-600 { background-color: var(--color-danger-600) !important; }
.bg-danger-700 { background-color: var(--color-danger-700) !important; }
.bg-danger-800 { background-color: var(--color-danger-800) !important; }
.bg-danger-900 { background-color: var(--color-danger-900) !important; }
.bg-info { background-color: var(--color-info) !important; }
.bg-info-100 { background-color: var(--color-info-100) !important; }
.bg-info-200 { background-color: var(--color-info-200) !important; }
.bg-info-300 { background-color: var(--color-info-300) !important; }
.bg-info-400 { background-color: var(--color-info-400) !important; }
.bg-info-500 { background-color: var(--color-info-500) !important; }
.bg-info-600 { background-color: var(--color-info-600) !important; }
.bg-info-700 { background-color: var(--color-info-700) !important; }
.bg-info-800 { background-color: var(--color-info-800) !important; }
.bg-info-900 { background-color: var(--color-info-900) !important; }
.bg-warning { background-color: var(--color-warning) !important; }
.bg-warning-100 { background-color: var(--color-warning-100) !important; }
.bg-warning-200 { background-color: var(--color-warning-200) !important; }
.bg-warning-300 { background-color: var(--color-warning-300) !important; }
.bg-warning-400 { background-color: var(--color-warning-400) !important; }
.bg-warning-500 { background-color: var(--color-warning-500) !important; }
.bg-warning-600 { background-color: var(--color-warning-600) !important; }
.bg-warning-700 { background-color: var(--color-warning-700) !important; }
.bg-warning-800 { background-color: var(--color-warning-800) !important; }
.bg-warning-900 { background-color: var(--color-warning-900) !important; }
.bg-success { background-color: var(--color-success) !important; }
.bg-success-100 { background-color: var(--color-success-100) !important; }
.bg-success-200 { background-color: var(--color-success-200) !important; }
.bg-success-300 { background-color: var(--color-success-300) !important; }
.bg-success-400 { background-color: var(--color-success-400) !important; }
.bg-success-500 { background-color: var(--color-success-500) !important; }
.bg-success-600 { background-color: var(--color-success-600) !important; }
.bg-success-700 { background-color: var(--color-success-700) !important; }
.bg-success-800 { background-color: var(--color-success-800) !important; }
.bg-success-900 { background-color: var(--color-success-900) !important; }
.bg-gray { background-color: var(--color-gray) !important; }
.bg-gray-100 { background-color: var(--color-gray-100) !important; }
.bg-gray-200 { background-color: var(--color-gray-200) !important; }
.bg-gray-300 { background-color: var(--color-gray-300) !important; }
.bg-gray-400 { background-color: var(--color-gray-400) !important; }
.bg-gray-500 { background-color: var(--color-gray-500) !important; }
.bg-gray-600 { background-color: var(--color-gray-600) !important; }
.bg-gray-700 { background-color: var(--color-gray-700) !important; }
.bg-gray-800 { background-color: var(--color-gray-800) !important; }
.bg-gray-900 { background-color: var(--color-gray-900) !important; }
.bg-white { background-color: var(--color-white) !important; }
.bg-black { background-color: var(--color-black) !important; }
.bg-transparent { background-color: transparent !important; }
