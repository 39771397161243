.tc-chart-js-legend {
  list-style-type: none;
  padding-left: 0;
}

.tc-chart-js-legend li {
  display: inline-block;
  margin: 3px;
}

.tc-chart-js-legend li span {
  border-radius: 50%;
  display: inline-block;
  height: 5px;
  margin-right: 5px;
  width: 5px;
}

.stats strong {
  color: var(--color-primary);
  font-family: var(--heading-font-family);
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.3;
  margin: 0;
}

.stats .details {
  font-family: var(--body-font-family);
  font-size: 0.75rem;
  font-weight: 400;
}

.radial-progress {
  background-color: var(--circle-background);
}

.radial-progress .circle .mask .fill {
  background-color: var(--circle-color);
}

.radial-progress .inset .count .number span {
  color: var(--percentage-color);
}

.radial-progress.success {
  --percentage-color: var(--color-success);
  --circle-background: var(--color-success-200);
  --circle-color: var(--color-success);
}

.radial-progress.info {
  --percentage-color: var(--color-info);
  --circle-background: var(--color-info-200);
  --circle-color: var(--color-info);
}

.radial-progress.warning {
  --percentage-color: var(--color-warning);
  --circle-background: var(--color-warning-200);
  --circle-color: var(--color-warning);
}

.radial-progress.danger {
  --percentage-color: var(--color-danger);
  --circle-background: var(--color-danger-200);
  --circle-color: var(--color-danger);
}

/* The radial progress directive */

@media (max-width: 480px) {
  .radial-progress {
    --inset-color: var(--color-white);
    --transition-length: 1s;
    --circle-size: 50px;
    --inset-size: 40px;
    --percentage-font-size: 16px;
    --percentage-text-width: 27px;

    width: var(--circle-size);
    height: var(--circle-size);
  }

  .radial-progress .circle .mask,
  .radial-progress .circle .fill {
    width: var(--circle-size);
    height: var(--circle-size);
  }

  .radial-progress .circle .mask {
    clip: rect(0, var(--circle-size), var(--circle-size), calc(var(--circle-size) / 2));
  }

  .radial-progress .circle .mask .fill {
    clip: rect(0, calc(var(--circle-size) / 2), var(--circle-size), 0);
  }

  .radial-progress .inset {
    width: var(--inset-size);
    height: var(--inset-size);
    margin-left: calc((var(--circle-size) - var(--inset-size)) / 2);
    margin-top: calc((var(--circle-size) - var(--inset-size)) / 2);
  }

  .radial-progress .inset .count {
    height: calc(var(--percentage-font-size) * 1.5);
    width: calc(var(--percentage-text-width) * 1.5);
    top: calc((var(--inset-size) - var(--percentage-font-size) * 1.5) / 2);
    left: calc((var(--inset-size) - var(--percentage-text-width)) / 2);
  }

  .radial-progress .inset .count .number span {
    width: var(--percentage-text-width);
    font-size: var(--percentage-font-size);
  }

  .radial-progress .legend {
    margin-top: calc(var(--circle-size) + 10);
    width: var(--circle-size);
  }
}

@media (max-width: 768px) {
  .radial-progress {
    --circle-size: 80px;
    --inset-size: 70px;
    --percentage-font-size: 26px;
    --percentage-text-width: 57px;
  }
}

@media (min-width: 768px) {
  .radial-progress {
    --circle-size: 120px;
    --inset-size: 110px;
    --percentage-font-size: 26px;
    --percentage-text-width: 57px;

    border-radius: 50%;
  }

  /* stylelint-disable a11y/media-prefers-reduced-motion -- The a11y plugin is buggy and doesn't recognize the media device query. */
  .radial-progress .circle .mask,
  .radial-progress .circke .fill {
    backface-visibility: hidden;
    border-radius: 50%;
    position: absolute;
    transition: transform var(--transition-length);
  }
  /* stylelint-enable a11y/media-prefers-reduced-motion */

  .radial-progress .inset {
    background-color: var(--inset-color);
    border-radius: 50%;
    position: absolute;
  }

  .radial-progress .inset .count {
    line-height: 1;
    overflow: hidden;
    position: absolute;
  }

  /* stylelint-disable -- The a11y plugin is buggy and doesn't recognize the media device query. */
  .radial-progress .inset .count .number {
    transition: width var(--transition-length);
  }
  /* stylelint-enable */

  .radial-progress .inset .count .number span {
    display: inline-block;
    text-align: center;
    vertical-align: top;
  }

  .radial-progress .inset .count .number span.details {
    font-size: 12px;
  }

  .radial-progress .legend {
    position: absolute;
    text-align: center;
  }

  .radial-progress[data-progress-percentage="1"] .circle .mask.full,
  .radial-progress[data-progress-percentage="1"] .circle .fill {
    transform: rotate(1.8deg);
  }

  .radial-progress[data-progress-percentage="1"] .circle .fill.fix {
    transform: rotate(3.6deg);
  }

  .radial-progress[data-progress-percentage="2"] .circle .mask.full,
  .radial-progress[data-progress-percentage="2"] .circle .fill {
    transform: rotate(3.6deg);
  }

  .radial-progress[data-progress-percentage="2"] .circle .fill.fix {
    transform: rotate(7.2deg);
  }

  .radial-progress[data-progress-percentage="3"] .circle .mask.full,
  .radial-progress[data-progress-percentage="3"] .circle .fill {
    transform: rotate(5.4deg);
  }

  .radial-progress[data-progress-percentage="3"] .circle .fill.fix {
    transform: rotate(10.8deg);
  }

  .radial-progress[data-progress-percentage="4"] .circle .mask.full,
  .radial-progress[data-progress-percentage="4"] .circle .fill {
    transform: rotate(7.2deg);
  }

  .radial-progress[data-progress-percentage="4"] .circle .fill.fix {
    transform: rotate(14.4deg);
  }

  .radial-progress[data-progress-percentage="5"] .circle .mask.full,
  .radial-progress[data-progress-percentage="5"] .circle .fill {
    transform: rotate(9deg);
  }

  .radial-progress[data-progress-percentage="5"] .circle .fill.fix {
    transform: rotate(18deg);
  }

  .radial-progress[data-progress-percentage="6"] .circle .mask.full,
  .radial-progress[data-progress-percentage="6"] .circle .fill {
    transform: rotate(10.8deg);
  }

  .radial-progress[data-progress-percentage="6"] .circle .fill.fix {
    transform: rotate(21.6deg);
  }

  .radial-progress[data-progress-percentage="7"] .circle .mask.full,
  .radial-progress[data-progress-percentage="7"] .circle .fill {
    transform: rotate(12.6deg);
  }

  .radial-progress[data-progress-percentage="7"] .circle .fill.fix {
    transform: rotate(25.2deg);
  }

  .radial-progress[data-progress-percentage="8"] .circle .mask.full,
  .radial-progress[data-progress-percentage="8"] .circle .fill {
    transform: rotate(14.4deg);
  }

  .radial-progress[data-progress-percentage="8"] .circle .fill.fix {
    transform: rotate(28.8deg);
  }

  .radial-progress[data-progress-percentage="9"] .circle .mask.full,
  .radial-progress[data-progress-percentage="9"] .circle .fill {
    transform: rotate(16.2deg);
  }

  .radial-progress[data-progress-percentage="9"] .circle .fill.fix {
    transform: rotate(32.4deg);
  }

  .radial-progress[data-progress-percentage="10"] .circle .mask.full,
  .radial-progress[data-progress-percentage="10"] .circle .fill {
    transform: rotate(18deg);
  }

  .radial-progress[data-progress-percentage="10"] .circle .fill.fix {
    transform: rotate(36deg);
  }

  .radial-progress[data-progress-percentage="11"] .circle .mask.full,
  .radial-progress[data-progress-percentage="11"] .circle .fill {
    transform: rotate(19.8deg);
  }

  .radial-progress[data-progress-percentage="11"] .circle .fill.fix {
    transform: rotate(39.6deg);
  }

  .radial-progress[data-progress-percentage="12"] .circle .mask.full,
  .radial-progress[data-progress-percentage="12"] .circle .fill {
    transform: rotate(21.6deg);
  }

  .radial-progress[data-progress-percentage="12"] .circle .fill.fix {
    transform: rotate(43.2deg);
  }

  .radial-progress[data-progress-percentage="13"] .circle .mask.full,
  .radial-progress[data-progress-percentage="13"] .circle .fill {
    transform: rotate(23.4deg);
  }

  .radial-progress[data-progress-percentage="13"] .circle .fill.fix {
    transform: rotate(46.8deg);
  }

  .radial-progress[data-progress-percentage="14"] .circle .mask.full,
  .radial-progress[data-progress-percentage="14"] .circle .fill {
    transform: rotate(25.2deg);
  }

  .radial-progress[data-progress-percentage="14"] .circle .fill.fix {
    transform: rotate(50.4deg);
  }

  .radial-progress[data-progress-percentage="15"] .circle .mask.full,
  .radial-progress[data-progress-percentage="15"] .circle .fill {
    transform: rotate(27deg);
  }

  .radial-progress[data-progress-percentage="15"] .circle .fill.fix {
    transform: rotate(54deg);
  }

  .radial-progress[data-progress-percentage="16"] .circle .mask.full,
  .radial-progress[data-progress-percentage="16"] .circle .fill {
    transform: rotate(28.8deg);
  }

  .radial-progress[data-progress-percentage="16"] .circle .fill.fix {
    transform: rotate(57.6deg);
  }

  .radial-progress[data-progress-percentage="17"] .circle .mask.full,
  .radial-progress[data-progress-percentage="17"] .circle .fill {
    transform: rotate(30.6deg);
  }

  .radial-progress[data-progress-percentage="17"] .circle .fill.fix {
    transform: rotate(61.2deg);
  }

  .radial-progress[data-progress-percentage="18"] .circle .mask.full,
  .radial-progress[data-progress-percentage="18"] .circle .fill {
    transform: rotate(32.4deg);
  }

  .radial-progress[data-progress-percentage="18"] .circle .fill.fix {
    transform: rotate(64.8deg);
  }

  .radial-progress[data-progress-percentage="19"] .circle .mask.full,
  .radial-progress[data-progress-percentage="19"] .circle .fill {
    transform: rotate(34.2deg);
  }

  .radial-progress[data-progress-percentage="19"] .circle .fill.fix {
    transform: rotate(68.4deg);
  }

  .radial-progress[data-progress-percentage="20"] .circle .mask.full,
  .radial-progress[data-progress-percentage="20"] .circle .fill {
    transform: rotate(36deg);
  }

  .radial-progress[data-progress-percentage="20"] .circle .fill.fix {
    transform: rotate(72deg);
  }

  .radial-progress[data-progress-percentage="21"] .circle .mask.full,
  .radial-progress[data-progress-percentage="21"] .circle .fill {
    transform: rotate(37.8deg);
  }

  .radial-progress[data-progress-percentage="21"] .circle .fill.fix {
    transform: rotate(75.6deg);
  }

  .radial-progress[data-progress-percentage="22"] .circle .mask.full,
  .radial-progress[data-progress-percentage="22"] .circle .fill {
    transform: rotate(39.6deg);
  }

  .radial-progress[data-progress-percentage="22"] .circle .fill.fix {
    transform: rotate(79.2deg);
  }

  .radial-progress[data-progress-percentage="23"] .circle .mask.full,
  .radial-progress[data-progress-percentage="23"] .circle .fill {
    transform: rotate(41.4deg);
  }

  .radial-progress[data-progress-percentage="23"] .circle .fill.fix {
    transform: rotate(82.8deg);
  }

  .radial-progress[data-progress-percentage="24"] .circle .mask.full,
  .radial-progress[data-progress-percentage="24"] .circle .fill {
    transform: rotate(43.2deg);
  }

  .radial-progress[data-progress-percentage="24"] .circle .fill.fix {
    transform: rotate(86.4deg);
  }

  .radial-progress[data-progress-percentage="25"] .circle .mask.full,
  .radial-progress[data-progress-percentage="25"] .circle .fill {
    transform: rotate(45deg);
  }

  .radial-progress[data-progress-percentage="25"] .circle .fill.fix {
    transform: rotate(90deg);
  }

  .radial-progress[data-progress-percentage="26"] .circle .mask.full,
  .radial-progress[data-progress-percentage="26"] .circle .fill {
    transform: rotate(46.8deg);
  }

  .radial-progress[data-progress-percentage="26"] .circle .fill.fix {
    transform: rotate(93.6deg);
  }

  .radial-progress[data-progress-percentage="27"] .circle .mask.full,
  .radial-progress[data-progress-percentage="27"] .circle .fill {
    transform: rotate(48.6deg);
  }

  .radial-progress[data-progress-percentage="27"] .circle .fill.fix {
    transform: rotate(97.2deg);
  }

  .radial-progress[data-progress-percentage="28"] .circle .mask.full,
  .radial-progress[data-progress-percentage="28"] .circle .fill {
    transform: rotate(50.4deg);
  }

  .radial-progress[data-progress-percentage="28"] .circle .fill.fix {
    transform: rotate(100.8deg);
  }

  .radial-progress[data-progress-percentage="29"] .circle .mask.full,
  .radial-progress[data-progress-percentage="29"] .circle .fill {
    transform: rotate(52.2deg);
  }

  .radial-progress[data-progress-percentage="29"] .circle .fill.fix {
    transform: rotate(104.4deg);
  }

  .radial-progress[data-progress-percentage="30"] .circle .mask.full,
  .radial-progress[data-progress-percentage="30"] .circle .fill {
    transform: rotate(54deg);
  }

  .radial-progress[data-progress-percentage="30"] .circle .fill.fix {
    transform: rotate(108deg);
  }

  .radial-progress[data-progress-percentage="31"] .circle .mask.full,
  .radial-progress[data-progress-percentage="31"] .circle .fill {
    transform: rotate(55.8deg);
  }

  .radial-progress[data-progress-percentage="31"] .circle .fill.fix {
    transform: rotate(111.6deg);
  }

  .radial-progress[data-progress-percentage="32"] .circle .mask.full,
  .radial-progress[data-progress-percentage="32"] .circle .fill {
    transform: rotate(57.6deg);
  }

  .radial-progress[data-progress-percentage="32"] .circle .fill.fix {
    transform: rotate(115.2deg);
  }

  .radial-progress[data-progress-percentage="33"] .circle .mask.full,
  .radial-progress[data-progress-percentage="33"] .circle .fill {
    transform: rotate(59.4deg);
  }

  .radial-progress[data-progress-percentage="33"] .circle .fill.fix {
    transform: rotate(118.8deg);
  }

  .radial-progress[data-progress-percentage="34"] .circle .mask.full,
  .radial-progress[data-progress-percentage="34"] .circle .fill {
    transform: rotate(61.2deg);
  }

  .radial-progress[data-progress-percentage="34"] .circle .fill.fix {
    transform: rotate(122.4deg);
  }

  .radial-progress[data-progress-percentage="35"] .circle .mask.full,
  .radial-progress[data-progress-percentage="35"] .circle .fill {
    transform: rotate(63deg);
  }

  .radial-progress[data-progress-percentage="35"] .circle .fill.fix {
    transform: rotate(126deg);
  }

  .radial-progress[data-progress-percentage="36"] .circle .mask.full,
  .radial-progress[data-progress-percentage="36"] .circle .fill {
    transform: rotate(64.8deg);
  }

  .radial-progress[data-progress-percentage="36"] .circle .fill.fix {
    transform: rotate(129.6deg);
  }

  .radial-progress[data-progress-percentage="37"] .circle .mask.full,
  .radial-progress[data-progress-percentage="37"] .circle .fill {
    transform: rotate(66.6deg);
  }

  .radial-progress[data-progress-percentage="37"] .circle .fill.fix {
    transform: rotate(133.2deg);
  }

  .radial-progress[data-progress-percentage="38"] .circle .mask.full,
  .radial-progress[data-progress-percentage="38"] .circle .fill {
    transform: rotate(68.4deg);
  }

  .radial-progress[data-progress-percentage="38"] .circle .fill.fix {
    transform: rotate(136.8deg);
  }

  .radial-progress[data-progress-percentage="39"] .circle .mask.full,
  .radial-progress[data-progress-percentage="39"] .circle .fill {
    transform: rotate(70.2deg);
  }

  .radial-progress[data-progress-percentage="39"] .circle .fill.fix {
    transform: rotate(140.4deg);
  }

  .radial-progress[data-progress-percentage="40"] .circle .mask.full,
  .radial-progress[data-progress-percentage="40"] .circle .fill {
    transform: rotate(72deg);
  }

  .radial-progress[data-progress-percentage="40"] .circle .fill.fix {
    transform: rotate(144deg);
  }

  .radial-progress[data-progress-percentage="41"] .circle .mask.full,
  .radial-progress[data-progress-percentage="41"] .circle .fill {
    transform: rotate(73.8deg);
  }

  .radial-progress[data-progress-percentage="41"] .circle .fill.fix {
    transform: rotate(147.6deg);
  }

  .radial-progress[data-progress-percentage="42"] .circle .mask.full,
  .radial-progress[data-progress-percentage="42"] .circle .fill {
    transform: rotate(75.6deg);
  }

  .radial-progress[data-progress-percentage="42"] .circle .fill.fix {
    transform: rotate(151.2deg);
  }

  .radial-progress[data-progress-percentage="43"] .circle .mask.full,
  .radial-progress[data-progress-percentage="43"] .circle .fill {
    transform: rotate(77.4deg);
  }

  .radial-progress[data-progress-percentage="43"] .circle .fill.fix {
    transform: rotate(154.8deg);
  }

  .radial-progress[data-progress-percentage="44"] .circle .mask.full,
  .radial-progress[data-progress-percentage="44"] .circle .fill {
    transform: rotate(79.2deg);
  }

  .radial-progress[data-progress-percentage="44"] .circle .fill.fix {
    transform: rotate(158.4deg);
  }

  .radial-progress[data-progress-percentage="45"] .circle .mask.full,
  .radial-progress[data-progress-percentage="45"] .circle .fill {
    transform: rotate(81deg);
  }

  .radial-progress[data-progress-percentage="45"] .circle .fill.fix {
    transform: rotate(162deg);
  }

  .radial-progress[data-progress-percentage="46"] .circle .mask.full,
  .radial-progress[data-progress-percentage="46"] .circle .fill {
    transform: rotate(82.8deg);
  }

  .radial-progress[data-progress-percentage="46"] .circle .fill.fix {
    transform: rotate(165.6deg);
  }

  .radial-progress[data-progress-percentage="47"] .circle .mask.full,
  .radial-progress[data-progress-percentage="47"] .circle .fill {
    transform: rotate(84.6deg);
  }

  .radial-progress[data-progress-percentage="47"] .circle .fill.fix {
    transform: rotate(169.2deg);
  }

  .radial-progress[data-progress-percentage="48"] .circle .mask.full,
  .radial-progress[data-progress-percentage="48"] .circle .fill {
    transform: rotate(86.4deg);
  }

  .radial-progress[data-progress-percentage="48"] .circle .fill.fix {
    transform: rotate(172.8deg);
  }

  .radial-progress[data-progress-percentage="49"] .circle .mask.full,
  .radial-progress[data-progress-percentage="49"] .circle .fill {
    transform: rotate(88.2deg);
  }

  .radial-progress[data-progress-percentage="49"] .circle .fill.fix {
    transform: rotate(176.4deg);
  }

  .radial-progress[data-progress-percentage="50"] .circle .mask.full,
  .radial-progress[data-progress-percentage="50"] .circle .fill {
    transform: rotate(90deg);
  }

  .radial-progress[data-progress-percentage="50"] .circle .fill.fix {
    transform: rotate(180deg);
  }

  .radial-progress[data-progress-percentage="51"] .circle .mask.full,
  .radial-progress[data-progress-percentage="51"] .circle .fill {
    transform: rotate(91.8deg);
  }

  .radial-progress[data-progress-percentage="51"] .circle .fill.fix {
    transform: rotate(183.6deg);
  }

  .radial-progress[data-progress-percentage="52"] .circle .mask.full,
  .radial-progress[data-progress-percentage="52"] .circle .fill {
    transform: rotate(93.6deg);
  }

  .radial-progress[data-progress-percentage="52"] .circle .fill.fix {
    transform: rotate(187.2deg);
  }

  .radial-progress[data-progress-percentage="53"] .circle .mask.full,
  .radial-progress[data-progress-percentage="53"] .circle .fill {
    transform: rotate(95.4deg);
  }

  .radial-progress[data-progress-percentage="53"] .circle .fill.fix {
    transform: rotate(190.8deg);
  }

  .radial-progress[data-progress-percentage="54"] .circle .mask.full,
  .radial-progress[data-progress-percentage="54"] .circle .fill {
    transform: rotate(97.2deg);
  }

  .radial-progress[data-progress-percentage="54"] .circle .fill.fix {
    transform: rotate(194.4deg);
  }

  .radial-progress[data-progress-percentage="55"] .circle .mask.full,
  .radial-progress[data-progress-percentage="55"] .circle .fill {
    transform: rotate(99deg);
  }

  .radial-progress[data-progress-percentage="55"] .circle .fill.fix {
    transform: rotate(198deg);
  }

  .radial-progress[data-progress-percentage="56"] .circle .mask.full,
  .radial-progress[data-progress-percentage="56"] .circle .fill {
    transform: rotate(100.8deg);
  }

  .radial-progress[data-progress-percentage="56"] .circle .fill.fix {
    transform: rotate(201.6deg);
  }

  .radial-progress[data-progress-percentage="57"] .circle .mask.full,
  .radial-progress[data-progress-percentage="57"] .circle .fill {
    transform: rotate(102.6deg);
  }

  .radial-progress[data-progress-percentage="57"] .circle .fill.fix {
    transform: rotate(205.2deg);
  }

  .radial-progress[data-progress-percentage="58"] .circle .mask.full,
  .radial-progress[data-progress-percentage="58"] .circle .fill {
    transform: rotate(104.4deg);
  }

  .radial-progress[data-progress-percentage="58"] .circle .fill.fix {
    transform: rotate(208.8deg);
  }

  .radial-progress[data-progress-percentage="59"] .circle .mask.full,
  .radial-progress[data-progress-percentage="59"] .circle .fill {
    transform: rotate(106.2deg);
  }

  .radial-progress[data-progress-percentage="59"] .circle .fill.fix {
    transform: rotate(212.4deg);
  }

  .radial-progress[data-progress-percentage="60"] .circle .mask.full,
  .radial-progress[data-progress-percentage="60"] .circle .fill {
    transform: rotate(108deg);
  }

  .radial-progress[data-progress-percentage="60"] .circle .fill.fix {
    transform: rotate(216deg);
  }

  .radial-progress[data-progress-percentage="61"] .circle .mask.full,
  .radial-progress[data-progress-percentage="61"] .circle .fill {
    transform: rotate(109.8deg);
  }

  .radial-progress[data-progress-percentage="61"] .circle .fill.fix {
    transform: rotate(219.6deg);
  }

  .radial-progress[data-progress-percentage="62"] .circle .mask.full,
  .radial-progress[data-progress-percentage="62"] .circle .fill {
    transform: rotate(111.6deg);
  }

  .radial-progress[data-progress-percentage="62"] .circle .fill.fix {
    transform: rotate(223.2deg);
  }

  .radial-progress[data-progress-percentage="63"] .circle .mask.full,
  .radial-progress[data-progress-percentage="63"] .circle .fill {
    transform: rotate(113.4deg);
  }

  .radial-progress[data-progress-percentage="63"] .circle .fill.fix {
    transform: rotate(226.8deg);
  }

  .radial-progress[data-progress-percentage="64"] .circle .mask.full,
  .radial-progress[data-progress-percentage="64"] .circle .fill {
    transform: rotate(115.2deg);
  }

  .radial-progress[data-progress-percentage="64"] .circle .fill.fix {
    transform: rotate(230.4deg);
  }

  .radial-progress[data-progress-percentage="65"] .circle .mask.full,
  .radial-progress[data-progress-percentage="65"] .circle .fill {
    transform: rotate(117deg);
  }

  .radial-progress[data-progress-percentage="65"] .circle .fill.fix {
    transform: rotate(234deg);
  }

  .radial-progress[data-progress-percentage="66"] .circle .mask.full,
  .radial-progress[data-progress-percentage="66"] .circle .fill {
    transform: rotate(118.8deg);
  }

  .radial-progress[data-progress-percentage="66"] .circle .fill.fix {
    transform: rotate(237.6deg);
  }

  .radial-progress[data-progress-percentage="67"] .circle .mask.full,
  .radial-progress[data-progress-percentage="67"] .circle .fill {
    transform: rotate(120.6deg);
  }

  .radial-progress[data-progress-percentage="67"] .circle .fill.fix {
    transform: rotate(241.2deg);
  }

  .radial-progress[data-progress-percentage="68"] .circle .mask.full,
  .radial-progress[data-progress-percentage="68"] .circle .fill {
    transform: rotate(122.4deg);
  }

  .radial-progress[data-progress-percentage="68"] .circle .fill.fix {
    transform: rotate(244.8deg);
  }

  .radial-progress[data-progress-percentage="69"] .circle .mask.full,
  .radial-progress[data-progress-percentage="69"] .circle .fill {
    transform: rotate(124.2deg);
  }

  .radial-progress[data-progress-percentage="69"] .circle .fill.fix {
    transform: rotate(248.4deg);
  }

  .radial-progress[data-progress-percentage="70"] .circle .mask.full,
  .radial-progress[data-progress-percentage="70"] .circle .fill {
    transform: rotate(126deg);
  }

  .radial-progress[data-progress-percentage="70"] .circle .fill.fix {
    transform: rotate(252deg);
  }

  .radial-progress[data-progress-percentage="71"] .circle .mask.full,
  .radial-progress[data-progress-percentage="71"] .circle .fill {
    transform: rotate(127.8deg);
  }

  .radial-progress[data-progress-percentage="71"] .circle .fill.fix {
    transform: rotate(255.6deg);
  }

  .radial-progress[data-progress-percentage="72"] .circle .mask.full,
  .radial-progress[data-progress-percentage="72"] .circle .fill {
    transform: rotate(129.6deg);
  }

  .radial-progress[data-progress-percentage="72"] .circle .fill.fix {
    transform: rotate(259.2deg);
  }

  .radial-progress[data-progress-percentage="73"] .circle .mask.full,
  .radial-progress[data-progress-percentage="73"] .circle .fill {
    transform: rotate(131.4deg);
  }

  .radial-progress[data-progress-percentage="73"] .circle .fill.fix {
    transform: rotate(262.8deg);
  }

  .radial-progress[data-progress-percentage="74"] .circle .mask.full,
  .radial-progress[data-progress-percentage="74"] .circle .fill {
    transform: rotate(133.2deg);
  }

  .radial-progress[data-progress-percentage="74"] .circle .fill.fix {
    transform: rotate(266.4deg);
  }

  .radial-progress[data-progress-percentage="75"] .circle .mask.full,
  .radial-progress[data-progress-percentage="75"] .circle .fill {
    transform: rotate(135deg);
  }

  .radial-progress[data-progress-percentage="75"] .circle .fill.fix {
    transform: rotate(270deg);
  }

  .radial-progress[data-progress-percentage="76"] .circle .mask.full,
  .radial-progress[data-progress-percentage="76"] .circle .fill {
    transform: rotate(136.8deg);
  }

  .radial-progress[data-progress-percentage="76"] .circle .fill.fix {
    transform: rotate(273.6deg);
  }

  .radial-progress[data-progress-percentage="77"] .circle .mask.full,
  .radial-progress[data-progress-percentage="77"] .circle .fill {
    transform: rotate(138.6deg);
  }

  .radial-progress[data-progress-percentage="77"] .circle .fill.fix {
    transform: rotate(277.2deg);
  }

  .radial-progress[data-progress-percentage="78"] .circle .mask.full,
  .radial-progress[data-progress-percentage="78"] .circle .fill {
    transform: rotate(140.4deg);
  }

  .radial-progress[data-progress-percentage="78"] .circle .fill.fix {
    transform: rotate(280.8deg);
  }

  .radial-progress[data-progress-percentage="79"] .circle .mask.full,
  .radial-progress[data-progress-percentage="79"] .circle .fill {
    transform: rotate(142.2deg);
  }

  .radial-progress[data-progress-percentage="79"] .circle .fill.fix {
    transform: rotate(284.4deg);
  }

  .radial-progress[data-progress-percentage="80"] .circle .mask.full,
  .radial-progress[data-progress-percentage="80"] .circle .fill {
    transform: rotate(144deg);
  }

  .radial-progress[data-progress-percentage="80"] .circle .fill.fix {
    transform: rotate(288deg);
  }

  .radial-progress[data-progress-percentage="81"] .circle .mask.full,
  .radial-progress[data-progress-percentage="81"] .circle .fill {
    transform: rotate(145.8deg);
  }

  .radial-progress[data-progress-percentage="81"] .circle .fill.fix {
    transform: rotate(291.6deg);
  }

  .radial-progress[data-progress-percentage="82"] .circle .mask.full,
  .radial-progress[data-progress-percentage="82"] .circle .fill {
    transform: rotate(147.6deg);
  }

  .radial-progress[data-progress-percentage="82"] .circle .fill.fix {
    transform: rotate(295.2deg);
  }

  .radial-progress[data-progress-percentage="83"] .circle .mask.full,
  .radial-progress[data-progress-percentage="83"] .circle .fill {
    transform: rotate(149.4deg);
  }

  .radial-progress[data-progress-percentage="83"] .circle .fill.fix {
    transform: rotate(298.8deg);
  }

  .radial-progress[data-progress-percentage="84"] .circle .mask.full,
  .radial-progress[data-progress-percentage="84"] .circle .fill {
    transform: rotate(151.2deg);
  }

  .radial-progress[data-progress-percentage="84"] .circle .fill.fix {
    transform: rotate(302.4deg);
  }

  .radial-progress[data-progress-percentage="85"] .circle .mask.full,
  .radial-progress[data-progress-percentage="85"] .circle .fill {
    transform: rotate(153deg);
  }

  .radial-progress[data-progress-percentage="85"] .circle .fill.fix {
    transform: rotate(306deg);
  }

  .radial-progress[data-progress-percentage="86"] .circle .mask.full,
  .radial-progress[data-progress-percentage="86"] .circle .fill {
    transform: rotate(154.8deg);
  }

  .radial-progress[data-progress-percentage="86"] .circle .fill.fix {
    transform: rotate(309.6deg);
  }

  .radial-progress[data-progress-percentage="87"] .circle .mask.full,
  .radial-progress[data-progress-percentage="87"] .circle .fill {
    transform: rotate(156.6deg);
  }

  .radial-progress[data-progress-percentage="87"] .circle .fill.fix {
    transform: rotate(313.2deg);
  }

  .radial-progress[data-progress-percentage="88"] .circle .mask.full,
  .radial-progress[data-progress-percentage="88"] .circle .fill {
    transform: rotate(158.4deg);
  }

  .radial-progress[data-progress-percentage="88"] .circle .fill.fix {
    transform: rotate(316.8deg);
  }

  .radial-progress[data-progress-percentage="89"] .circle .mask.full,
  .radial-progress[data-progress-percentage="89"] .circle .fill {
    transform: rotate(160.2deg);
  }

  .radial-progress[data-progress-percentage="89"] .circle .fill.fix {
    transform: rotate(320.4deg);
  }

  .radial-progress[data-progress-percentage="90"] .circle .mask.full,
  .radial-progress[data-progress-percentage="90"] .circle .fill {
    transform: rotate(162deg);
  }

  .radial-progress[data-progress-percentage="90"] .circle .fill.fix {
    transform: rotate(324deg);
  }

  .radial-progress[data-progress-percentage="91"] .circle .mask.full,
  .radial-progress[data-progress-percentage="91"] .circle .fill {
    transform: rotate(163.8deg);
  }

  .radial-progress[data-progress-percentage="91"] .circle .fill.fix {
    transform: rotate(327.6deg);
  }

  .radial-progress[data-progress-percentage="92"] .circle .mask.full,
  .radial-progress[data-progress-percentage="92"] .circle .fill {
    transform: rotate(165.6deg);
  }

  .radial-progress[data-progress-percentage="92"] .circle .fill.fix {
    transform: rotate(331.2deg);
  }

  .radial-progress[data-progress-percentage="93"] .circle .mask.full,
  .radial-progress[data-progress-percentage="93"] .circle .fill {
    transform: rotate(167.4deg);
  }

  .radial-progress[data-progress-percentage="93"] .circle .fill.fix {
    transform: rotate(334.8deg);
  }

  .radial-progress[data-progress-percentage="94"] .circle .mask.full,
  .radial-progress[data-progress-percentage="94"] .circle .fill {
    transform: rotate(169.2deg);
  }

  .radial-progress[data-progress-percentage="94"] .circle .fill.fix {
    transform: rotate(338.4deg);
  }

  .radial-progress[data-progress-percentage="95"] .circle .mask.full,
  .radial-progress[data-progress-percentage="95"] .circle .fill {
    transform: rotate(171deg);
  }

  .radial-progress[data-progress-percentage="95"] .circle .fill.fix {
    transform: rotate(342deg);
  }

  .radial-progress[data-progress-percentage="96"] .circle .mask.full,
  .radial-progress[data-progress-percentage="96"] .circle .fill {
    transform: rotate(172.8deg);
  }

  .radial-progress[data-progress-percentage="96"] .circle .fill.fix {
    transform: rotate(345.6deg);
  }

  .radial-progress[data-progress-percentage="97"] .circle .mask.full,
  .radial-progress[data-progress-percentage="97"] .circle .fill {
    transform: rotate(174.6deg);
  }

  .radial-progress[data-progress-percentage="97"] .circle .fill.fix {
    transform: rotate(349.2deg);
  }

  .radial-progress[data-progress-percentage="98"] .circle .mask.full,
  .radial-progress[data-progress-percentage="98"] .circle .fill {
    transform: rotate(176.4deg);
  }

  .radial-progress[data-progress-percentage="98"] .circle .fill.fix {
    transform: rotate(352.8deg);
  }

  .radial-progress[data-progress-percentage="99"] .circle .mask.full,
  .radial-progress[data-progress-percentage="99"] .circle .fill {
    transform: rotate(178.2deg);
  }

  .radial-progress[data-progress-percentage="99"] .circle .fill.fix {
    transform: rotate(356.4deg);
  }

  .radial-progress[data-progress-percentage="100"] .circle .mask.full,
  .radial-progress[data-progress-percentage="100"] .circle .fill {
    transform: rotate(180deg);
  }

  .radial-progress[data-progress-percentage="100"] .circle .fill.fix {
    transform: rotate(360deg);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .radial-progress .circle .mask,
  .radial-progress .circke .fill {
    transition: none;
  }

  .radial-progress .inset .count .number {
    transition: none;
  }
}
