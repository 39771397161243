.box {
  background-color: var(--color-primary-100);
  border-radius: 3px;
  border: 1px solid var(--color-primary-200);
  color: var(--color-primary-700);
  margin: 0.64em 0;
  padding: 0.4em;
}

.box.box-default {
  background-color: var(--color-gray-100);
  border-color: var(--color-gray-200);
  color: var(--color-gray-700);
}

.box.box-primary {
  background-color: var(--color-primary-100);
  border-color: var(--color-primary-200);
  color: var(--color-primary-700);
}

.box.box-success {
  background-color: var(--color-success-100);
  border-color: var(--color-success-200);
  color: var(--color-success-700);
}

.box.box-danger {
  background-color: var(--color-danger-100);
  border-color: var(--color-danger-200);
  color: var(--color-danger-700);
}

.box.box-info {
  background-color: var(--color-info-100);
  border-color: var(--color-info-200);
  color: var(--color-info-700);
}

.box.box-warning {
  background-color: var(--color-warning-100);
  border-color: var(--color-warning-200);
  color: var(--color-warning-700);
}

.ub-box {
  border: 1px solid var(--color-gray-700);
  margin: var(--size-gutter) 0;
}

.ub-box__header {
  align-items: center;
  background-color: var(--color-info-200);
  border-bottom: 1px solid var(--color-info-300);
  display: flex;
  justify-content: space-between;
  margin-left: calc(0 - var(--size-gutter));
  margin-right: calc(0 - var(--size-gutter));
  margin-top: calc(0 - var(--size-gutter));
  padding: calc(var(--size-gutter) / 2) var(--size-gutter);
}

.ub-box__content {
  padding: calc(var(--size-gutter) / 2) var(--size-gutter);
}

.ub-box__header .list-icon-actions {
  padding-top: 0;
}

.ub-box--rounded {
  border-radius: var(--border-radius);
}

.ub-box--dotted {
  border: var(--border-style-dotted);
}

.ub-box .ub-box .ub-box__header {
  background-color: var(--color-info-100);
  border-bottom-color: var(--color-info-200);
}

.ub-box__header--transparent,
.ub-box .ub-box .ub-box__header--transparent {
  background-color: transparent;
}

.ub-box .ub-box {
  border-color: var(--color-gray-700);
}

.ub-box .ub-box .ub-box {
  border-color: var(--color-gray-600);
}

.ub-box .ub-box .ub-box .ub-box {
  border-color: var(--color-gray-500);
}

.ub-box .ub-box .ub-box .ub-box .ub-box {
  border-color: var(--color-gray-400);
}

.ub-box .ub-box .ub-box .ub-box .ub-box .ub-box {
  border-color: var(--color-gray-300);
}

.ub-box .ub-box .ub-box .ub-box .ub-box .ub-box .ub-box {
  border-color: var(--color-gray-200);
}


@media (prefers-contrast: more) {
  .box {
    border-color: var(--color-primary-300);
    color: var(--color-primary-800);
  }

  .box.box-default {
    border-color: var(--color-gray-300);
    color: var(--color-gray-800);
  }

  .box.box-primary {
    border-color: var(--color-primary-300);
    color: var(--color-primary-800);
  }

  .box.box-success {
    border-color: var(--color-success-300);
    color: var(--color-success-800);
  }

  .box.box-danger {
    border-color: var(--color-danger-300);
    color: var(--color-danger-800);
  }

  .box.box-info {
    border-color: var(--color-info-300);
    color: var(--color-info-800);
  }

  .box.box-warning {
    border-color: var(--color-warning-300);
    color: var(--color-warning-800);
  }
}
