body.modal-open {
  overflow: hidden;
  position: relative;
}

.modal-header .close {
  margin-right: 20px;
  margin-top: 10px;
}

.modal-content .modal-header {
  padding: 20px 0 0 0;
}

.modal-content .modal-title {
  border-bottom: 1px solid var(--color-gray);
  padding: 20px;
}

.modal-content .modal-body {
  height: 430px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px;
}

.modal-content .modal-body::-webkit-scrollbar-track {
  background-color: var(--color-gray-300);
}

.modal-content .modal-body::-webkit-scrollbar {
  background-color: var(--color-gray-300);
  border-radius: var(--button-border-radius-lg);
  width: 6px;
}

.modal-content .modal-body::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-700);
  border-radius: var(--button-border-radius-lg);
}

.modal-content .modal-footer {
  border-top: 1px solid var(--color-gray);
  text-align: left;
}

.modal-content .modal-body + .modal-footer {
  padding: 20px;
}
