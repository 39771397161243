@media print {
  body {
    background-color: var(--color-white) !important;
    box-shadow: none !important;
    color: var(--color-black);
    font-family: Arial, sans-serif;
    font-size: 9pt;
    text-shadow: none !important;
  }

  .print-ready,
  .print-ready .wrapper {
    background-color: var(--color-white) !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  #header-menu,
  #sidebar-left,
  #offline-ui,
  #status-ui,
  #env-ui {
    display: none;
  }

  #atlwdg-trigger {
    display: none;
  }

  video,
  audio,
  object,
  embed {
    display: none;
  }

  .btn,
  .sequoia-button,
  .sequoia-search-form input[name="search"] {
    display: none;
  }

  img {
    max-width: 100% !important;
    page-break-inside: avoid;
  }

  a,
  a:visited {
    color: var(--color-gray);
    text-decoration: underline;
  }

  p a[href^="http://"]::after,
  a[href^="https://"]::after {
    content: " (" attr(href) ")";
  }

  /* stylelint-disable-next-line no-descending-specificity */
  p a {
    word-wrap: break-word;
  }

  .wrapper .row-bordered,
  .wrapper > .row,
  .wrapper > .row > .row {
    box-shadow: none;
    padding: 5px 10px;
  }

  div[class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }

  label {
    color: var(--color-black);
    font-weight: normal;
    padding-top: 5px;
  }

  .no-print {
    display: none;
  }

  .print-only {
    display: block;
  }

  thead {
    display: table-header-group;
  }

  tr {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: var(--color-black) !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid var(--color-gray-300) !important;
  }

  .report-table {
    break-inside: avoid-page;
    margin-bottom: 40px;
  }

  .report-detailed .report-detailed-item,
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    font-size: 9pt;
  }

  .box {
    display: none;
  }

  .label {
    border: 0;
  }

  .ng-hide {
    display: none;
  }
}
