/* stylelint-disable */
/* Sequoia */
.sequoia .sequoia-search .sequoia-search-form,
.sequoia .sequoia-tree .sequoia-item-title {
  width: 80%;
}

.sequoia .sequoia-search .sequoia-actions,
.sequoia .sequoia-tree .sequoia-item-actions {
  width: 20%;
}

.sequoia .sequoia-button {
  font-size: 13px;
}
.sequoia .sequoia-breadcrumbs li {
  display: block;
  opacity: 0.5;
}

.sequoia .sequoia-breadcrumbs li:last-child,
.sequoia .sequoia-breadcrumbs li:hover {
  opacity: 1;
}

.sequoia .sequoia-button.sequoia-button-primary,
.sequoia .sequoia-button.sequoia-button-success,
.sequoia .sequoia-button.sequoia-button-info,
.sequoia .sequoia-button.sequoia-button-warning,
.sequoia .sequoia-button.sequoia-button-danger,
.sequoia .sequoia-button.sequoia-button-default {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
}

.sequoia .sequoia-button.sequoia-button-primary {
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.sequoia .sequoia-button.sequoia-button-primary:hover,
.sequoia .sequoia-button.sequoia-button-success:hover,
.sequoia .sequoia-button.sequoia-button-info:hover,
.sequoia .sequoia-button.sequoia-button-warning:hover,
.sequoia .sequoia-button.sequoia-button-danger:hover,
.sequoia .sequoia-button.sequoia-button-default:hover {
  background-color: transparent;
}

.sequoia .sequoia-button.sequoia-button-success {
  border-color: var(--color-success);
  color: var(--color-success);
}

.sequoia .sequoia-button.sequoia-button-danger {
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.sequoia .sequoia-button.sequoia-button-info {
  border-color: var(--color-info);
  color: var(--color-info);
}

.sequoia .sequoia-button.sequoia-button-warning {
  border-color: var(--color-warning);
  color: var(--color-warning);
}

.sequoia .sequoia-button.sequoia-button-default {
  border-color: var(--color-gray);
  color: var(--color-gray);
}
/* stylelint-enable */
