/* stylelint-disable value-list-comma-space-after,property-no-unknown */

/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-RegularItalic-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-RegularItalic-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-BoldItalic-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-BoldItalic-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-Regular-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-Regular-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-Bold-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../../assets/font/DM-Sans-Bold-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Icon fonts */
@font-face {
  font-family: 'epf';
  src: url('src/assets/font/epf.eot');
  src:
    url('src/assets/font/epf.eot#iefix') format('embedded-opentype'),
    url('src/assets/font/epf.woff2') format('woff2'),
    url('src/assets/font/epf.woff') format('woff'),
    url('src/assets/font/epf.ttf') format('truetype'),
    url('src/assets/font/epf.svg#epf') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  display: inline-block;
  font-family: 'epf';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.icon-spin::before { content: '\e800'; } /* '' */
.icon-lock::before { content: '\e801'; } /* '' */
.icon-lock-open::before { content: '\e802'; } /* '' */
.icon-download::before { content: '\e803'; } /* '' */
.icon-bell::before { content: '\e804'; } /* '' */
.icon-trash::before { content: '\e805'; } /* '' */
.icon-chart-pie::before { content: '\e806'; } /* '' */
.icon-level-down::before { content: '\e807'; } /* '' */
.icon-home::before { content: '\e808'; } /* '' */
.icon-archive::before { content: '\e809'; } /* '' */
.icon-menu-dots::before { content: '\e80a'; } /* '' */
.icon-text-align-left::before { content: '\e80b'; } /* '' */
.icon-text-align-center::before { content: '\e80c'; } /* '' */
.icon-text-align-right::before { content: '\e80d'; } /* '' */
.icon-text-align-justify::before { content: '\e80e'; } /* '' */
.icon-folder::before { content: '\e80f'; } /* '' */
.icon-text-bold::before { content: '\e810'; } /* '' */
.icon-settings::before { content: '\e811'; } /* '' */
.icon-text-italic::before { content: '\e812'; } /* '' */
.icon-save::before { content: '\e813'; } /* '' */
.icon-userFields::before { content: '\e814'; } /* '' */
.icon-mail::before { content: '\e815'; } /* '' */
.icon-ok::before { content: '\e816'; } /* '' */
.icon-search::before { content: '\e817'; } /* '' */
.icon-plus::before { content: '\e818'; } /* '' */
.icon-minus::before { content: '\e819'; } /* '' */
.icon-upload::before { content: '\e81a'; } /* '' */
.icon-autosave::before { content: '\e81b'; } /* '' */
.icon-down-open-big::before { content: '\e81c'; } /* '' */
.icon-left-open-big::before { content: '\e81d'; } /* '' */
.icon-right-open-big::before { content: '\e81e'; } /* '' */
.icon-up-open-big::before { content: '\e81f'; } /* '' */
.icon-reload::before { content: '\e820'; } /* '' */
.icon-calendar::before { content: '\e822'; } /* '' */
.icon-warning::before { content: '\e823'; } /* '' */
.icon-preview::before { content: '\e825'; } /* '' */
.icon-move::before { content: '\e826'; } /* '' */
.icon-star::before { content: '\e828'; } /* '' */
.icon-pencil-edit::before { content: '\e829'; } /* '' */
.icon-menu::before { content: '\e82c'; } /* '' */
.icon-cancel::before { content: '\e82d'; } /* '' */
.icon-cog::before { content: '\e831'; } /* '' */
.icon-user::before { content: '\e832'; } /* '' */
.icon-check::before { content: '\e834'; } /* '' */
.icon-view::before { content: '\e836'; } /* '' */
.icon-edit::before { content: '\e838'; } /* '' */
.icon-undo::before { content: '\e839'; } /* '' */
.icon-tags::before { content: '\e83a'; } /* '' */
.icon-attachment::before { content: '\e83b'; } /* '' */
.icon-comment::before { content: '\e83c'; } /* '' */
.icon-history::before { content: '\e83d'; } /* '' */
.icon-split::before { content: '\e83e'; } /* '' */
.icon-link::before { content: '\e83f'; } /* '' */
.icon-flag::before { content: '\e840'; } /* '' */
.icon-goal::before { content: '\e841'; } /* '' */
.icon-pin::before { content: '\e842'; } /* '' */
.icon-info::before { content: '\f086'; } /* '' */
.icon-duplicate::before { content: '\f0c5'; } /* '' */
.icon-list-bullet::before { content: '\f0ca'; } /* '' */
.icon-list-numbered::before { content: '\f0cb'; } /* '' */
.icon-strike::before { content: '\f0cc'; } /* '' */
.icon-text-underline::before { content: '\f0cd'; } /* '' */
.icon-sitemap::before { content: '\f0e8'; } /* '' */
.icon-arrow-double-left::before { content: '\f100'; } /* '' */
.icon-arrow-double-right::before { content: '\f101'; } /* '' */
.icon-arrow-left::before { content: '\f104'; } /* '' */
.icon-arrow-right::before { content: '\f105'; } /* '' */
.icon-text-quote::before { content: '\f10e'; } /* '' */
.icon-unlink::before { content: '\f127'; } /* '' */
.icon-file-pdf::before { content: '\f1c1'; } /* '' */
.icon-file-word::before { content: '\f1c2'; } /* '' */
.icon-file-excel::before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint::before { content: '\f1c4'; } /* '' */
.icon-file-image::before { content: '\f1c5'; } /* '' */
.icon-file-archive::before { content: '\f1c6'; } /* '' */
.icon-file-audio::before { content: '\f1c7'; } /* '' */
.icon-file-video::before { content: '\f1c8'; } /* '' */
.icon-toggle-off::before { content: '\f204'; } /* '' */
.icon-toggle-on::before { content: '\f205'; } /* '' */
.icon-hourglass::before { content: '\f252'; } /* '' */
