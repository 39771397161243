.dropdown-menu .dropdown-header {
  color: var(--color-gray);
  font-size: 0.7125rem;
  padding-left: 20px;
  text-transform: uppercase;
}

.dropdown-menu li {
  padding: 5px 0;
}

.dropdown-menu .divider {
  padding: 0;
}

#navbar .top-search {
  color: var(--color-gray);
}

#navbar .top-search.active {
  color: var(--color-gray-200);
}

#navbar .divider-left {
  border-left-color: var(--color-gray-200);
}

#navbar .divider-right {
  border-right-color: var(--color-gray-200);
}

.navbar-header .navbar-toggle {
  color: var(--color-primary);
}

.profile-dropdown, .profile-dropdown:focus, .profile-dropdown:hover {
  text-decoration: none;
}

#logged-in-as-menu {
  align-items: center;
  background-color: var(--color-danger);
  display: flex;
  justify-content: space-between;
  padding: 0.4em 1.4em;
}

#logged-in-as-menu p {
  color: var(--color-gray-100);
}

.masquerade-mode #header-menu {
  border-bottom: 4px solid var(--color-danger);
  box-shadow: none;
}

#remote-header-menu {
  background-color: var(--color-info);
  padding-left: 20px;
  padding-right: 20px;
  z-index: 5;
}

#remote-header-menu .nav > li > p {
  color: var(--color-white);
  margin: 0;
  padding: 1em 0;
}

#remote-header-menu .navbar-toggle,
#remote-header-menu .caret {
  color: var(--color-white);
}

#remote-header-menu .navbar-toggle {
  float: none;
}

#remote-header-menu .navbar-nav {
  display: inline-block;
  padding-top: 0;
}

#remote-header-menu .navbar-right {
  float: right;
}

#remote-header-menu .remote-profile-dropdown .avatar a {
  padding: 0;
}

#remote-header-menu #remote-navbar .navbar-nav-links > li > a {
  color: var(--color-white);
}

#remote-header-menu #remote-navbar .navbar-nav > .active {
  background-color: var(--color-info-800);
}

#remote-header-menu #remote-navbar .navbar-nav > .active .caret {
  color: var(--body-background);
}

#remote-header-menu #remote-navbar .dropdown-menu {
  background-color: var(--color-info);
}

/* stylelint-disable no-descending-specificity */
#remote-header-menu #remote-navbar .dropdown-menu li,
#remote-header-menu #remote-navbar .dropdown-menu li > p,
#remote-header-menu #remote-navbar .dropdown-menu li > p .text-gray,
#remote-header-menu #remote-navbar .dropdown-menu li > a {
  color: var(--body-background);
}

#remote-header-menu #remote-navbar .dropdown-menu li > a:hover,
#remote-header-menu #remote-navbar .dropdown-menu li > a:focus {
  color: var(--color-info-600);
}

#remote-header-menu #remote-navbar .dropdown-menu .active,
#remote-header-menu #remote-navbar .dropdown-menu .active:hover,
#remote-header-menu #remote-navbar .dropdown-menu .active:focus,
#remote-header-menu #remote-navbar .dropdown-menu .active > a,
#remote-header-menu #remote-navbar .dropdown-menu .active:hover > a,
#remote-header-menu #remote-navbar .dropdown-menu .active:focus > a,
#remote-header-menu #remote-navbar .dropdown-menu .active:hover > a:hover,
#remote-header-menu #remote-navbar .dropdown-menu .active:focus > a:hover,
#remote-header-menu #remote-navbar .dropdown-menu .active:focus > a:focus {
  background-color: var(--color-info-600);
  color: var(--body-background);
}

#remote-header-menu #remote-navbar .dropdown-menu li:hover,
#remote-header-menu #remote-navbar .dropdown-menu li:focus,
#remote-header-menu #remote-navbar .dropdown-menu li:hover > a,
#remote-header-menu #remote-navbar .dropdown-menu li:focus > a,
#remote-header-menu #remote-navbar .dropdown-menu li:hover > a:hover,
#remote-header-menu #remote-navbar .dropdown-menu li:focus > a:hover,
#remote-header-menu #remote-navbar .dropdown-menu li:hover > a:focus,
#remote-header-menu #remote-navbar .dropdown-menu li:focus > a:focus {
  background-color: var(--color-info-600);
  color: var(--body-background);
}

#remote-header-menu #remote-navbar .navbar-nav > .active > a {
  background-color: transparent;
  color: var(--body-background);
}
/* stylelint-enable no-descending-specificity */

/* Headroom */
.headroom--pinned {
  transform: translateY(0%);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.headroom {
  left: 0;
  position: fixed;
  top: 0;
  transition: transform 200ms linear;
  width: 100%;
  z-index: 9999;
}

@media (min-width: 768px) {
  #remote-header-menu .navbar-nav-links {
    margin-left: 100px;
  }

  #unsynced-bar {
    margin-top: -15px;
  }
}

@media (min-width: 992px) {
  #remote-header-menu .navbar-collapse.collapse {
    display: inline-block !important;
  }
}

@media (max-width: 1200px) {
  #collapse-navbar {
    display: inline-block !important;
  }
}

/*
 * kz-unsynced-bar styles
*/
#unsynced-bar {
  left: 0;
  margin-top: -9px;
  padding: 0;
  position: fixed;
  right: 0;
  z-index: 1000;
}

#unsynced-bar .alert {
  margin: 0;
  padding: 0 15px;
  text-align: center;
}

@media screen and (prefers-reduced-motion: reduce) {
  .headroom {
    transition: none;
  }
}
