.kz-image {
  appearance: none;
  border: 0;
  box-sizing: border-box;
  inset: 0px;
  outline: 0;
  padding: 0px;
  vertical-align: middle;
}

.kz-image::after,
.kz-image::before {
  box-sizing: border-box;
}

.kz-image[data-image-layout="scale-down"] {
  max-width: 100%;
}

.kz-image[data-image-layout="responsive"] {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.kz-image[data-image-layout="cover"] {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
