/*  FIXME: This should all be removed when redoing the forms, it's very nasty */
@media (min-width: 768px) {
  .announcement-basic-form .formly-field {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }

  .announcement-basic-form > .formly-field:nth-child(2),
  .announcement-basic-form > .formly-field:nth-child(3),
  .announcement-basic-form > .formly-field:nth-child(4) {
    float: left;
    margin-right: 3%;
    width: 30%;
  }

  .announcement-basic-form > .formly-field:nth-child(4) {
    margin-right: 0;
  }

  .announcement-basic-form > .formly-field:nth-child(4)::after {
    clear: both;
  }

  .announcement-basic-form > .formly-field:first-child,
  .announcement-basic-form > .formly-field:nth-child(5) {
    width: 100%;
  }

  .announcement-basic-form > .formly-field:first-child::before,
  .announcement-basic-form > .formly-field:first-child::after,
  .announcement-basic-form > .formly-field:nth-child(5)::before,
  .announcement-basic-form > .formly-field:nth-child(5)::after {
    content: " ";
    display: table;
  }

  .announcement-basic-form > .formly-field:first-child::after,
  .announcement-basic-form > .formly-field:nth-child(5)::after {
    clear: both;
  }
}
