.event p {
  margin: 5px 0;
}

.event-users {
  background-color: var(--color-gray-200);
  margin-bottom: 5px;
}

.event-progress li {
  padding-left: 2px;
  padding-right: 2px;
}

.event-users p,
.event-users li {
  color: var(--color-gray);
}

.event-users li {
  padding: 10px 5px;
}

.event-users li:first-child {
  padding-left: 0;
}

.event-group .event-group-title {
  background-color: var(--color-gray-200);
  margin: 10px 0;
  padding: 0.8em 0.64em 0.64em;
}

.event-group .event-group-title p {
  color: var(--color-gray-700);
  line-height: 1;
  margin: 0;
}

.event-group .wrapper {
  margin-bottom: 5px;
}

.event {
  border-radius: 3px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 5px 10px 0 0 !important;
}

.event .event-users {
  margin-right: -25px;
  margin-top: -5px;
}

.event .event-inner {
  padding-bottom: 5px !important;
}

.event .event-inner .col-right {
  text-align: left;
}

.event .event-inner .list-inline li {
  padding: 0 10px 0 0;
}

.event .event-inner .list-inline li:last-child {
  padding-right: 0;
}

.event .event-inner .label {
  white-space: normal;
}

.event a {
  text-decoration: none;
}

.event h2 {
  color: var(--color-success);
  font-family: var(--body-font-family);
  font-size: 1.225rem;
}

.event h2 i {
  color: var(--color-gray-400);
  font-size: 1rem;
  margin: 0 3px;
}

.event.log-event h2,
.event.log-event a,
.event.log-event p,
.event.log-event i,
.event.log-event .label {
  color: var(--color-gray);
}

.event.log-event a:hover,
.event.log-event a:focus,
.event.log-event a:active {
  color: var(--color-gray-400);
}

.event.log-event .label-color-dot {
  background-color: var(--color-gray);
}

.event.log-event.progress-border {
  border-left-color: var(--color-gray);
}

.event .row.buttons {
  background-color: var(--color-gray-100);
  border-bottom-right-radius: 3px;
  margin-right: -25px;
  padding: 0.64em 0;
}

.event .row.buttons ul.list-inline.list-unstyled > li {
  display: inline-block;
  margin: 1em;
}

.event .row.buttons ul.list-inline.list-unstyled > li:first-child {
  margin-left: 0;
  margin-right: 0;
}

.event .row.buttons ul.list-inline.list-unstyled > li:last-child {
  margin-left: 0;
  margin-right: 0;
}

.event .progress > .progress-bar-success {
  background-color: var(--color-success);
}

.progress-border {
  border-left-style: solid;
  border-left-width: 5px;
}

.progress-border.progress-border-complete {
  border-left-color: var(--color-success);
}

.progress-border.progress-border-pending {
  border-left-color: var(--color-warning);
}

.progress-border.progress-border-danger {
  border-left-color: var(--color-danger);
}

.progress-border.progress-border-info {
  border-left-color: var(--color-info);
}

.event-modal-header .row {
  margin-left: 0;
  margin-right: 0;
}

.audit-log {
  border-top-width: 1px;
  border-top-style: solid;
  margin-left: 0;
  margin-right: -15px;
}

.audit-log > .row {
  padding-bottom: 10px;
  padding-top: 10px;
}

.audit-log > .row:last-child {
  border-bottom: none;
}

.events-group {
  margin-bottom: 20px;
}

.events-group .group-title {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.events-group .event.progress-border {
  border-left: 0;
}

.events-group .event-group {
  border-bottom: 1px solid var(--color-gray-200);
}

.events-group .event .entry-title {
  color: var(--color-primary);
  font-family: var(--body-font-family);
  font-weight: normal;
}

.events-group .wrapper {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}

.events-group.smaller .event .entry-title {
  font-size: 1.125rem;
}

.drafts-list .events-group .event-inner > div:first-child {
  margin-top: 10px;
}

.event-section-progress-state {
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  display: inline-block;
  height: 12px;
  padding-left: 4px;
  padding-right: 4px;
  width: 12px;
}

.event-section-progress-state.event-section-progress-state--complete {
  background-color: var(--color-success);
  border-color: var(--color-success);
}

.event-section-progress-state.event-section-progress-state--skipped {
  background-color: var(--color-gray-400);
  border-color: var(--color-gray-400);
}

.event-section-progress-state.event-section-progress-state--pending {
  background-color: var(--color-warning);
  border-color: var(--color-warning);
}

.event-section-progress-state.event-section-progress-state--draft {
  background-color: transparent;
  border-color: var(--color-warning);
}

.event-section-progress-state.event-section-progress-state--approval {
  background-color: transparent;
  border-color: var(--color-success);
}

.event-section-progress-state.event-section-progress-state--other {
  background-color: transparent;
  border-color: var(--color-gray-400);
}

.compact .event-section-progress-state {
  height: 8px;
  padding-left: 0;
  padding-right: 0;
  width: 8px;
}

.meta-author {
  color: var(--color-primary);
}

.meta-date {
  color: var(--color-black);
}

.event.event-draft h2 {
  color: var(--color-warning);
}

.event-log {
  list-style-position: inside;
}

.event .custom-fields .control-label {
  margin-top: 5px;
  padding-top: 0;
}

.event .custom-fields .control-label::after {
  content: ': ';
}

.event .custom-fields .control-label,
.event .custom-fields .form-group .row > .col-xs-12 > div {
  display: inline;
}

.event-tag {
  background-color: var(--color-gray-200);
  border-radius: var(--button-border-radius-lg);
  color: var(--color-gray-700);
  display: inline-block;
  margin: 4px 0;
  padding: 2px 10px;
}

.event-modal-body {
  background-color: var(--body-background);
}

.stats {
  text-align: center;
  text-transform: uppercase;
}

.stats .stats-count {
  --size: 80px;

  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  display: inline-block;
  font-size: 1.875rem;
  height: var(--size);
  line-height: calc(var(--size) - 2);
  width: var(--size);
}

.stats .stats-description {
  margin-top: 7px;
}

.stats.stats-primary .stats-count,
.stats.stats-primary .stats-description {
  color: var(--color-primary);
}

.stats.stats-primary .stats-count {
  border-color: var(--color-primary);
}

.stats.stats-success .stats-count,
.stats.stats-success .stats-description {
  color: var(--color-success);
}

.stats.stats-success .stats-count {
  border-color: var(--color-success);
}

.stats.stats-info .stats-count,
.stats.stats-info .stats-description {
  color: var(--color-info);
}

.stats.stats-info .stats-count {
  border-color: var(--color-success);
}

.stats.stats-danger .stats-count,
.stats.stats-danger .stats-description {
  color: var(--color-danger);
}

.stats.stats-danger .stats-count {
  border-color: var(--color-danger);
}

.stats.stats-warning .stats-count,
.stats.stats-warning .stats-description {
  color: var(--color-warning);
}

.stats.stats-warning .stats-count {
  border-color: var(--color-warning);
}

@media (max-width: 768px) {
  .event-section-progress-state {
    height: 8px;
    padding-left: 0;
    padding-right: 0;
    width: 8px;
  }
}

@media (min-width: 768px) {
  .dotbuttons .open .dropdown-menu {
    left: auto;
    right: 0;
  }

  .event-extras-form .formly-field {
    display: inline-block;
    margin-left: 0;
    margin-right: 20px;
  }

  .event-basic-form .formly-field {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
  }

  .event-basic-form .formly-field--readonly {
    margin-right: 16px;
  }

  .event-basic-form > .formly-field:first-child,
  .event-basic-form > .formly-field:nth-child(2) {
    float: left;
    margin-right: 5%;
    width: 45%;
  }

  .event-basic-form > .formly-field:nth-child(2) {
    margin-right: 0;
  }

  .event-basic-form > .formly-field:nth-child(2)::after {
    clear: both;
  }

  .event-basic-form > .formly-field:nth-child(3) {
    /* @include clearfix; */
    width: 100%;
  }

  .event-basic-form > .formly-field:nth-child(3)::after,
  .event-basic-form > .formly-field:nth-child(3)::before {
    content: " ";
    display: table;
  }

  .event-basic-form > .formly-field:nth-child(3)::after {
    clear: both;
  }

  .event .event-inner .col-right {
    padding-right: 10px;
    text-align: right;
  }

  .event .row.buttons ul.list-inline.list-unstyled > li {
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .event .row.buttons > div > div > div:last-child {
    text-align: right;
  }
}
