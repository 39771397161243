html,
body {
  font-size: var(--base-font-size);
  font-feature-settings: "cv05", "cv08", "case";
}

body {
  background-color: var(--body-background);
  color: var(--font-color);
  cursor: var(--cursor-default-value);
  font-family: var(--body-font-family);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  line-height: var(--base-line-height);
  margin: 0;
  position: relative;
}

@media screen {
  body {
    padding: 0 0 40px 0;
  }
}

div,
dl,
dt,
dd,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style-position: inside;
  margin: 0 0 0 14px;
  padding: 0;
}

::-moz-selection {
  background-color: var(--color-primary-800);
  color: var(--color-primary-100);
}

::selection {
  background-color: var(--color-primary-800);
  color: var(--color-primary-100);
}

body,
p,
div,
button,
input,
select,
textarea,
a {
  font-family: var(--body-font-family);
  font-weight: 300;
}

/* Default header styles */
h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  color: var(--color-primary);
  font-family: var(--heading-font-family);
}

/* Default header styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 2px;
  margin-top: 2px;
}

/* Default paragraph styles */
p {
  font-size: 1rem;
  line-height: 140%;
  margin: 0.88em 0;
}

li {
  font-size: 1rem;
  line-height: 140%;
}

p,
li {
  color: var(--font-color);
}

h1 { font-size: 2.25rem; }
h2 { font-size: 1.875rem; }
h3 { font-size: 1.5rem; }
h4 { font-size: 1.25rem; }
h5 { font-size: 1.125rem; }

a {
  color: var(--link-color);
}

a:active,
a:hover {
  outline: none;
}

a:focus {
  outline: 2px dotted var(--link-color);
  outline-offset: 2px;
}

.highlighted {
  color: var(--color-warning);
  font-weight: bold;
}

.slot-reloaded {
  background-color: var(--color-warning) !important;
}

@media screen {
  .print-only {
    display: none;
  }
}
