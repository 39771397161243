.border {
  border-style: solid;
  border-width: 1px;
}

.border-b {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.border-primary { border-color: var(--color-primary) !important; }
.border-primary-100 { border-color: var(--color-primary-100) !important; }
.border-primary-200 { border-color: var(--color-primary-200) !important; }
.border-primary-300 { border-color: var(--color-primary-300) !important; }
.border-primary-400 { border-color: var(--color-primary-400) !important; }
.border-primary-500 { border-color: var(--color-primary-500) !important; }
.border-primary-600 { border-color: var(--color-primary-600) !important; }
.border-primary-700 { border-color: var(--color-primary-700) !important; }
.border-primary-800 { border-color: var(--color-primary-800) !important; }
.border-primary-900 { border-color: var(--color-primary-900) !important; }
.border-danger { border-color: var(--color-danger) !important; }
.border-danger-100 { border-color: var(--color-danger-100) !important; }
.border-danger-200 { border-color: var(--color-danger-200) !important; }
.border-danger-300 { border-color: var(--color-danger-300) !important; }
.border-danger-400 { border-color: var(--color-danger-400) !important; }
.border-danger-500 { border-color: var(--color-danger-500) !important; }
.border-danger-600 { border-color: var(--color-danger-600) !important; }
.border-danger-700 { border-color: var(--color-danger-700) !important; }
.border-danger-800 { border-color: var(--color-danger-800) !important; }
.border-danger-900 { border-color: var(--color-danger-900) !important; }
.border-info { border-color: var(--color-info) !important; }
.border-info-100 { border-color: var(--color-info-100) !important; }
.border-info-200 { border-color: var(--color-info-200) !important; }
.border-info-300 { border-color: var(--color-info-300) !important; }
.border-info-400 { border-color: var(--color-info-400) !important; }
.border-info-500 { border-color: var(--color-info-500) !important; }
.border-info-600 { border-color: var(--color-info-600) !important; }
.border-info-700 { border-color: var(--color-info-700) !important; }
.border-info-800 { border-color: var(--color-info-800) !important; }
.border-info-900 { border-color: var(--color-info-900) !important; }
.border-warning { border-color: var(--color-warning) !important; }
.border-warning-100 { border-color: var(--color-warning-100) !important; }
.border-warning-200 { border-color: var(--color-warning-200) !important; }
.border-warning-300 { border-color: var(--color-warning-300) !important; }
.border-warning-400 { border-color: var(--color-warning-400) !important; }
.border-warning-500 { border-color: var(--color-warning-500) !important; }
.border-warning-600 { border-color: var(--color-warning-600) !important; }
.border-warning-700 { border-color: var(--color-warning-700) !important; }
.border-warning-800 { border-color: var(--color-warning-800) !important; }
.border-warning-900 { border-color: var(--color-warning-900) !important; }
.border-success { border-color: var(--color-success) !important; }
.border-success-100 { border-color: var(--color-success-100) !important; }
.border-success-200 { border-color: var(--color-success-200) !important; }
.border-success-300 { border-color: var(--color-success-300) !important; }
.border-success-400 { border-color: var(--color-success-400) !important; }
.border-success-500 { border-color: var(--color-success-500) !important; }
.border-success-600 { border-color: var(--color-success-600) !important; }
.border-success-700 { border-color: var(--color-success-700) !important; }
.border-success-800 { border-color: var(--color-success-800) !important; }
.border-success-900 { border-color: var(--color-success-900) !important; }
.border-gray { border-color: var(--color-gray) !important; }
.border-gray-100 { border-color: var(--color-gray-100) !important; }
.border-gray-200 { border-color: var(--color-gray-200) !important; }
.border-gray-300 { border-color: var(--color-gray-300) !important; }
.border-gray-400 { border-color: var(--color-gray-400) !important; }
.border-gray-500 { border-color: var(--color-gray-500) !important; }
.border-gray-600 { border-color: var(--color-gray-600) !important; }
.border-gray-700 { border-color: var(--color-gray-700) !important; }
.border-gray-800 { border-color: var(--color-gray-800) !important; }
.border-gray-900 { border-color: var(--color-gray-900) !important; }
.border-white { border-color: var(--color-white) !important; }
.border-black { border-color: var(--color-black) !important; }
.border-transparent { border-color: transparent !important; }
