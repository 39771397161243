.sortable-ghost {
  background: var(--color-info);
  opacity: 0.4;
}

.drag-handle {
  cursor: move;
}

.angular-ui-tree-empty {
  border: 1px dashed var(--color-gray-500);
  min-height: 30px;
}

.angular-ui-tree-nodes {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 20px;
}

.angular-ui-tree-node,
.angular-ui-tree-placeholder {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
}

.angular-ui-tree-hidden {
  display: none;
}

.angular-ui-tree-placeholder {
  background: var(--color-gray-100);
  border: 1px dashed var(--color-gray-200);
  box-sizing: border-box;
  margin: 5px 0;
  min-height: 20px;
  padding: 0;
}

.angular-ui-tree-handle {
  border: 1px dashed transparent;
  box-sizing: border-box;
  cursor: move;
  font-weight: bold;
  padding: 10px 0;
  text-decoration: none;
}

.angular-ui-tree-handle:hover,
.angular-ui-tree-handle:focus {
  border-color: var(--color-gray-200);
}

.angular-ui-tree-drag {
  opacity: 0.8;
  pointer-events: none;
  position: absolute;
  z-index: 999;
}

/* Active tree handles get the alert-warning class that has a bg color which overpowers the content */
.angular-ui-tree-handle.alert-warning {
  background-color: var(--color-warning-100);
  border: 1px solid var(--color-warning-200);
}
