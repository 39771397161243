.files-table {
  table-layout: auto;
}

.files-table td {
  vertical-align: middle !important;
}

.files-table .col-preview {
  width: 8em;
}

.files-table .col-filename {
  width: 48em;
}

.files-table .col-icon-actions {
  min-width: 8em;
}

.file-slot__title {
  align-items: center;
  display: flex;
  gap: 1em;
}

.files-table td:last-of-type,
.files-table th:last-of-type {
  text-align: end;
}

.files-table__actions {
  container: actions / inline-size;
}

.files-table__actions .button--text {
  display: none;
}

@container actions (inline-size > 200px) {
  .files-table__actions .button--text {
    display: inline-block;
  }
}

@media screen and (min-width: 768px) {
  .files-table .col-btn-actions {
    min-width: 16em;
  }
}
