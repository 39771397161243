legend {
  border-bottom: 0;
  font-size: 1.5rem;
}

textarea.form-control {
  min-height: 8em; /* Fits about 5 rows of text, essentialy rows="5" as a minimum */
}

.form-group {
  margin-top: 0 !important;
}

.form-control {
  background-color: var(--color-white) !important;
  border-color: var(--color-gray-400);
  font-size: 1rem;
}

.form-group .form-control {
  margin-bottom: 0;
}

.has-error .form-control {
  border-color: var(--color-danger);
}

.has-success .form-control {
  border-color: var(--color-success);
}

.no-csstransitions .form-control {
  border-bottom: 1px solid var(--color-gray);
}

.form-group.has-error .control-label {
  color: var(--color-danger) !important;
}

.form-group.has-error .form-control,
.form-group.has-error .form-wrap {
  color: var(--color-danger) !important;
  background-color: var(--color-warning-200);
}

.form-control-no-borders {
  background-image: none;
  border-color: transparent;
}

.form-control-with-icon {
  position: relative;
}

.form-control-with-icon::before {
  content: '\e801';
  color: var(--color-gray-400);
  display: inline-block;
  font-family: 'epf';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  left: 10px;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  position: absolute;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  top: 11px;
  width: 1em;
  z-index: 2;
}

.form-control-with-icon input {
  display: inline-block;
  font-family: 'epf';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  padding-left: 25px;
  position: relative;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.form-group label.control-label,
.form-field label.control-label,
.togglebutton label,
.form-group label {
  font-size: 0.88rem;
  font-weight: bold;
  margin-top: 0;
  padding-top: 0;
}

.togglebutton label,
.no-form-control label {
  font-size: 0.88rem;
  line-height: 1.07142857;
  margin: 16px 0 0 0;
}

.input-increment {
  background-color: var(--color-white);
}

.input-increment span {
  display: inline-block;
  font-size: 1.125rem;
  min-width: 25px;
  text-align: center;
  vertical-align: middle;
}

.input-increment button.disabled,
.input-increment button[disabled],
fieldset[disabled] .input-increment button {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}

.checkbox .checkbox-material {
  top: 5px;
}

.form-group .hint {
  display: none;
}

.form-group.has-error .hint {
  display: block;
}

.hint-text {
  color: var(--color-gray);
  font-size: 0.88rem;
  margin: 0;
  padding: 0;
}

.form-group label.control-label,
.form-field label.control-label,
.togglebutton label,
.no-form-control label,
.form-group label {
  color: var(--color-gray) !important;
}

.control-label ~ .text-gray {
  font-size: 0.8rem;
  font-style: italic;
  margin: 4px 0;
}

.form-group.is-focused label.control-label {
  color: var(--color-primary);
}

.form-group.is-focused .form-control {
  background-image: none;
}

.ui-select-match .btn:hover,
.ui-select-match .btn:focus {
  box-shadow: none !important;
}

.ui-select-container .ui-select-match .ui-select-toggle.btn,
.ui-select-container .ui-select-match .ui-select-toggle .btn {
  color: var(--font-color) !important;
}

.ui-select-container .ui-select-match .ui-select-toggle.btn {
  background-color: transparent !important;
  border-radius: 4px !important;
}

.inline .formly-field {
  display: inline-block;
  padding-right: 25px;
}

.ui-select-multiple.ui-select-bootstrap {
  padding: 6px;
}

.ui-select-container .ui-select-match-text {
  text-transform: none;
}

.ui-select-container .ui-select-match .btn.btn-xs.btn-link.pull-right {
  border-radius: 0;
  font-size: 1.125rem;
  line-height: 16px;
  margin-top: 0;
  position: static;
}

/* Custom file upload button */
.file-input {
  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.file-input__upload {
  overflow: hidden;
  position: relative;
}

.file-input input[type="file"] {
  -webkit-tap-highlight-color: transparent;
  bottom: 0;
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.file-input .file-input__selected {
  color: var(--color-gray-600);
  display: flex;
}

.file-input .file-input__selected li {
  border-radius: var(--button-border-radius-sm);
  display: inline-block;
}

.file-input .file-input__selected li p {
  font-size: 0.88rem;
  margin: 0.32em 0;
}

/* Readonly form */
.form-text__field-value {
  display: inline;
}

.form-text__control-label {
  color: var(--color-gray);
  display: inline;
  font-size: 0.9rem;
  font-weight: 700;
}

.form-readonly.form-readonly--fancy .form-text__form-group {
  margin: 20px 0;
}

.form-readonly.form-readonly--fancy .form-text__heading {
  font-size: 1.5rem;
}

.form-readonly.form-readonly--fancy .form-text__description,
.form-readonly.form-readonly--fancy .form-text__description p {
  color: var(--color-primary);
}

.form-readonly.form-readonly--fancy .form-text__control-label {
  color: var(--color-info);
  font-size: 1.125rem;
  font-weight: 600;
}

.form-readonly.form-readonly--fancy .form-text__field-value,
.form-readonly.form-readonly--fancy .form-text__control-label {
  display: block;
}

.form-readonly.form-readonly--fancy .form-text__field-value {
  background-color: var(--color-gray-200);
  border: 1px solid var(--color-gray-400);
  border-radius: 4px;
  font-size: 1.125rem;
  padding: 12px;
}

/* Images should not overflow the editor */
.formly-field .readonly p img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

/* Angular Facetly */
.facetly .facetly-search .facetly-searchbox {
  min-height: 41px;
  padding: 5px 0;
  width: 70%;
}

.facetly .facetly-search .facetly-searchbox .facetly-filters li {
  margin-bottom: 0;
  margin-top: 0;
}

.facetly .facetly-search .facetly-searchbox .facetly-typeahead-suggestions ul {
  max-height: 250px;
}

.facetly .facetly-search .facetly-buttons {
  padding-top: 10px;
  width: 30%;
}

@media (max-width: 768px) {
  .facetly .facetly-search .facetly-searchbox,
  .facetly .facetly-search .facetly-buttons {
    float: none;
    width: 100%;
  }
}

/* We overwrite the bootstrap-material-design form inputs */
/* stylelint-disable no-descending-specificity */
/* stylelint-disable a11y/media-prefers-reduced-motion -- The a11y plugin is buggy and doesn't recognize the media device query. */
.form-control,
.form-group .form-control {
  border-color: var(--color-gray-400);
  padding-left: 6px;
  transition: border-color ease-in 0.24s;
}
/* stylelint-enable a11y/media-prefers-reduced-motion */

.form-control:active,
.form-group .form-control:active,
.form-control:focus,
.form-group .form-control:focus {
  border-color: var(--color-primary);
}

.form-control.has-error,
.form-group.has-error .form-control {
  border-color: var(--color-danger);
}

.form-documents {
  background-color: var(--color-white);
  padding: 8px 16px;
}

.form-signature .signature {
  background-color: var(--color-white);
}

.form-documents .row > .row {
  margin-left: -0.6em;
}

form .wrapper {
  background-color: var(--color-gray-100);
}

.form--flex {
  display: flex;
  justify-content: space-between;
}

#kzt-search-query {
  margin-top: 10px;
  display: block;
  width: 100%;
  height: var(--input-height-base);
  padding: var(--padding-base-vertical) var(--padding-base-horizontal);
  font-size: var(--rem-base);
  line-height: var(--line-height-base);
  color: var(--color-gray);
  background-color: var(--color-white);
  background-image: none;
  border: 1px solid (--border-color);
  border-radius: var(--border-radius);
  box-shadow: inset 0 1px 1px hsla(0, 0%, 0%, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#kzt-search-query:focus {
  border-color: var(--color-info-400);
  box-shadow: inset 0 1px 1px hsla(0, 0%, 0%, 0.075);
  outline: 0;
}

#kzt-search-query::-moz-placeholder {
  color: var(--color-gray-700);
  opacity: 1;
}

#kzt-search-query:-ms-input-placeholder {
  color: var(--color-gray-700);
}

#kzt-search-query::-webkit-input-placeholder {
  color: var(--color-gray-700);
}

#kzt-search-query::-ms-expand {
  border: 0;
  background-color: transparent;
}

#kzt-search-query[disabled],
#kzt-search-query[readonly],
fieldset[disabled] #kzt-search-query {
  background-color: var(--color-gray-400);
  opacity: 1;
}

#kzt-search-query[disabled],
fieldset[disabled] #kzt-search-query {
  cursor: not-allowed;
}

.datepicker.datepicker-dropdown.dropdown-menu {
  z-index: 9999;
}

/* We overwrite the bootstrap-material-design form inputs */
.form-documents,
.form-signature .signature,
.form-control,
.form-group .form-control,
.form-control:active,
.form-group .form-control:active,
.form-control:focus,
.form-group .form-control:focus,
.form-control[disabled],
.form-group .form-control[disabled] {
  background-image: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding-left: 6px;
}

.form-documents__upload {
  margin-bottom: 8px;
}

.form-control:active:not(:disabled),
.form-control:focus:not(:disabled),
.form-group .form-control:active:not(:disabled),
.form-group .form-control:focus:not(:disabled) {
  background-color: var(--color-form-field-focus) !important;
  box-shadow: none;
}

select.form-control {
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAIAAAAmzuBxAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGQTdGMTE3NDA3MjA2ODExOTJCMEI4QUY2OEFGOTJFOSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0NDNBNDZGRUQwRUUxMUUyQkRGNEQ3NThBREE3RDhCQiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0NDNBNDZGREQwRUUxMUUyQkRGNEQ3NThBREE3RDhCQiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M0IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjAyODAxMTc0MDcyMDY4MTE5MkIwODgxQTcwOTI5MEE3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkZBN0YxMTc0MDcyMDY4MTE5MkIwQjhBRjY4QUY5MkU5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+0G/y9QAAAKFJREFUeNp8kLEKwyAQhqsUCbqECIKDDk4ZEpwSQYiv4HPm3VxDN+21KWmbpP3g4D/+Dw4OlVIuf0FgpJRyzseuaZorsCzLPM+nRoxRa40ZY33fH2spJdQQMMw4jlVVfd1GKISw5ocBtXPu02jbVgjxNoCu6zjnayaEeO83+2VgjKdpWvMwDJTSvQEopYwxdV1ba/f/2JbbE3jDT+OUuwADANi+KdCnCjqhAAAAAElFTkSuQmCC) !important;
  background-position: 98% 50% !important;
  background-repeat: no-repeat;
  background-size: auto !important;
  max-width: 100%;
  overflow: hidden;
  padding: 8px 16px 8px 8px;
}
/* stylelint-enable no-descending-specificity */

/* stylelint-disable */
/* KZ Tree remove when kz.tree is releasable */
.kz-tree .kzt-checkbox {
  display: inline-block;
}

.kz-tree ul {
  margin: 0;
  padding: 0;
}

.kz-tree ul li {
  list-style: none inside none;
  padding: 10px 3px;
}

@media (min-width: 768px) {
  .kz-tree ul li {
    padding: 5px 3px;
  }

  .kz-tree ul li ul {
    padding-left: 15px;
  }
}

.kz-tree ul li ul {
  padding-left: 7px;
}

.kz-tree .kzt-node-title a {
  font-weight: normal;
  text-decoration: none;
}

.kz-tree .kzt-node-title.is-active a {
  font-weight: bold;
}

.kz-tree .kzt-node-path {
  display: block;
  font-size: 75%;
  padding-left: 35px;
}

.kz-tree .kzt-toolbar::before,
.kz-tree .kzt-toolbar::after {
  content: " ";
  display: table;
}

.kz-tree .kzt-toolbar::after {
  clear: both;
}

.kz-tree .kzt-toolbar .kzt-toolbar-list li {
  display: inline-block;
}

.kz-tree .kzt-toolbar a.disabled {
  color: initial;
  cursor: text;
  text-decoration: none;
}
/* stylelint-enable */

@media screen and (prefers-reduced-motion: reduce) {
  #kzt-search-query {
    transition: none;
  }

  .form-control,
  .form-group .form-control {
    transition: none;
  }
}
