.user {
  padding-bottom: 0 !important;
}

.user a {
  text-decoration: none;
}

.user h2 {
  color: var(--color-success);
  font-family: var(--body-font-family);
  font-size: 1.5rem;
}

.user-profile-view-group {
  margin-bottom: 32px;
  padding-bottom: 12px;
}

.user.user-new h2 {
  color: var(--color-primary);
}

.user.user-active h2 {
  color: var(--color-success);
}

.user.user-disabled h2 {
  color: var(--color-warning);
}

.alphabet a:hover,
.alphabet a:active,
.alphabet a:focus,
.alphabet a:visited {
  text-decoration: none;
}

.alphabet .active a {
  color: var(--color-warning);
  cursor: default;
  text-decoration: none;
}
