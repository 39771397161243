.faqs {
  padding-bottom: 0 !important;
}

.faqs a {
  text-decoration: none;
}

.faqs h2 {
  color: var(--color-success);
  font-family: var(--body-font-family);
  font-size: 1.5rem;
}
