span.twitter-typeahead {
  display: inherit !important;
}

input.typeahead.tt-input {
  vertical-align: initial !important;
}

.twitter-typeahead + label::after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
  top: 65px;
  transition: 0.2s opacity ease-out, 0.2s color ease-out;
}

.twitter-typeahead ~ .material-icons {
  color: transparent;
  cursor: pointer;
  font-size: 2.25rem;
  position: absolute;
  right: 1em;
  top: 0;
  transition: 0.3s color;
}

.twitter-typeahead + label {
  left: 1em;
}

.twitter-typeahead .tt-menu {
  background-color: var(--color-white);
  box-shadow: 0 2px 5px 0 var(--color-box-shadow);
}

.twitter-typeahead .tt-menu .tt-suggestion {
  border-bottom-color: var(--color-gray-200);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 1em 1.4em;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover,
.twitter-typeahead .tt-menu .tt-suggestion:focus {
  background-color: var(--color-info);
  cursor: pointer;
  color: var(--color-white);
}

@media screen and (prefers-reduced-motion: reduce) {
  .twitter-typeahead + label::after {
    transition: none;
  }

  .twitter-typeahead ~ .material-icons {
    transition: none;
  }
}
