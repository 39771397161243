.glyphicon::before {
  display: inline-block;
  font-family: 'epf';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.glyphicon-ok::before {
  content: '\e834';
}

.glyphicon-remove::before {
  content: '\e805';
}

.glyphicon-chevron-right::before {
  content: '\e81e';
}

.glyphicon-chevron-left::before {
  content: '\e81d';
}

.glyphicon-edit::before {
  content: '\e829';
}

.glyphicon-cog::before {
  content: '\e831';
}

.glyphicon-move::before {
  content: '\e826';
}

.glyphicon-fullscreen::before {
  content: '\e821';
}

.glyphicon-plus-sign::before,
.glyphicon-plus::before {
  content: '\e818';
}

.glyphicon-minus::before {
  content: '\e819';
}

.glyphicon-save::before {
  content: '\e813';
}

.glyphicon-repeat::before {
  content: '\e82d';
}

.glyphicon-refresh::before {
  content: '\e820';
}

#loading-indicator {
  margin-left: -10px;
  position: fixed;
  width: 105%;
  height: 100%;
  background: var(--color-black) center center no-repeat;
  opacity: 0.5;
  text-align: center;
  z-index: 10000;
}

#loading-indicator i {
  color: var(--color-white);
  font-size: 2.25rem;
  margin: 25% auto;
}

.icon-menu-dots {
  font-size: 1.5rem;
}

.featured-icons {
  font-size: 1.5rem;
  padding: 6px;
}

.featured-icons:hover,
.featured-icons:focus {
  background-color: var(--color-gray-400);
}

.required {
  color: var(--color-danger);
}
