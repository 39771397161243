.animate-spin {
  animation: spin 1.4s infinite linear;
  display: inline-block;
}

/* (c) https://github.com/GriffinJohnston/uiball-loaders/blob/main/src/components/Pinwheel.js */
.pinwheel-loading__container {
  --pw-size: 3.125rem;
  --pw-color: var(--color-primary);
  --pw-line-weight: 4px;
  --pw-speed: 1s;

  align-items: center;
  display: flex;
  height: var(--pw-size);
  justify-content: center;
  margin: 0 auto;
  position: relative;
  width: var(--pw-size);
}

.pinwheel-loading__line {
  animation: rotate var(--pw-speed) ease-in-out infinite;
  background-color: var(--pw-color);
  border-radius: calc(var(--pw-line-weight) / 2);
  height: var(--pw-line-weight);
  left: 0;
  position: absolute;
  top: calc(50% - var(--pw-line-weight) / 2);
  width: 100%;
}

.pinwheel-loading__line:nth-child(2) {
  animation-delay: calc(var(--pw-speed) * 0.075);
  opacity: 0.8;
}

.pinwheel-loading__line:nth-child(3) {
  animation-delay: calc(var(--pw-speed) * 0.15);
  opacity: 0.6;
}

.pinwheel-loading__line:nth-child(4) {
  animation-delay: calc(var(--pw-speed) * 0.225);
  opacity: 0.4;
}

.pinwheel-loading__line:nth-child(5) {
  animation-delay: calc(var(--pw-speed) * 0.3);
  opacity: 0.2;
}

.pinwheel-loading__line:nth-child(6) {
  animation-delay: calc(var(--pw-speed) * 0.375);
  opacity: 0.1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

/* Animation for content placeholders */
@keyframes contentPlaceholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .animate-spin {
    animation: none;
  }

  .pinwheel-loading__line {
    animation: none;
  }
}
