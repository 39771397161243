.circle {
  --size: 20px;

  border: 1px solid var(--color-primary);
  border-radius: 50%;
  color: var(--color-primary);
  display: inline-block;
  font-size: calc(var(--size) / 2);
  height: var(--size);
  line-height: var(--size);
  text-align: center;
  width: var(--size);
}

.circle.info {
  border-color: var(--color-info);
  color: var(--color-info);
}

.circle.warning {
  border-color: var(--color-warning);
  color: var(--color-warning);
}

.circle.danger {
  border-color: var(--color-danger);
  color: var(--color-danger);
}

.circle.success {
  border-color: var(--color-success);
  color: var(--color-success);
}

.circle.circle-lg {
  --size: 100px;

  border-width: 5px;
  font-size: calc(var(--size) / 4);
  line-height: calc(var(--size) - var(size) / 10);
}

.refine-filters {
  background-color: var(--color-info);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: var(--color-white);
  padding: 10px 20px;
}

.refine-filters ul.list-inline {
  display: inline-block;
}

.refine-filters li,
.refine-filters a {
  color: var(--color-white) !important;
}

.refine-filters a {
  font-weight: bold;
  text-decoration: underline;
}

.label-filter {
  background-color: var(--color-gray-200);
  border-radius: var(--button-border-radius-lg);
  color: var(--color-gray);
  display: inline-block;
  padding: 2px 10px;
}

.label-filter a,
.label-filter a:hover,
.label-filter a:focus,
.label-filter a:active {
  color: var(--color-gray-600);
}

.label-connect {
  margin-left: 10px;
}

.refine-filters .dropdown-menu li a {
  color: var(--color-info) !important;
  text-decoration: none;
}

.refine-filters .dropdown-menu li a:hover,
.refine-filters .dropdown-menu li a:focus {
  color: var(--color-info-600) !important;
}

.filters .filter-label {
  padding-top: 5px;
}

.filters .by-letter li {
  padding: 0;
}

.list__filter {
  border: 1px solid transparent;
  border-radius: 4px;
  color: var(--color-gray);
  padding: 2px 4px;
}

.list__filter.list__filter--active {
  border-color: var(--color-primary);
  color: var(--color-primary);
  cursor: default;
}
