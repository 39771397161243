.avatar .avatar__name,
.avatar .avatar__image {
  display: inline-block;
  height: 30px;
  width: 30px;
}

.avatar .avatar__name {
  font-size: 0.88rem;
  line-height: 30px;
  text-align: center;
}

.avatar .avatar--big .avatar__name,
.avatar .avatar--big .avatar__image {
  display: inline-block;
  height: 120px;
  text-align: center;
  width: 120px;
}

.avatar .avatar--big .avatar__name {
  font-size: 2.25rem;
  line-height: 120px;
}

.avatar .dropdown-menu {
  margin: 0;
  min-width: 300px;
  max-width: 620px;
  padding: 0;
}

.avatar .open .dropdown-menu {
  background-color: var(--color-white);
  float: left;
  left: auto;
  position: absolute;
  right: 0;
}

.avatar .dropdown-menu li {
  padding: 0;
}

.avatar .dropdown-menu li.avatar-highlight {
  padding: 10px;
}

.avatar .dropdown-menu li .btn {
  margin: 0 !important;
}

.avatar .dropdown-menu li > a {
  display: inline-block;
}

.avatar .dropdown-menu li > a.btn-sm {
  font-size: 12px !important;
  padding: 5px 20px;
}

.avatar .avatar__name,
.avatar .avatar__image {
  border: 1px solid var(--color-gray-600);
  border-radius: var(--avatar-border-radius);
}

.avatar .avatar__name {
  background-color: var(--color-gray-300);
  color: var(--color-gray-700);
}

.avatar .avatar__image img {
  border-radius: var(--avatar-border-radius);
  object-fit: cover;
}

.avatar .dropdown-menu .avatar-highlight {
  color: var(--color-white);
  background-color: var(--color-primary);
}

.online .avatar__name,
.online .avatar__image {
  border-color: var(--color-success);
}

.offline .avatar__name,
.offline .avatar__image {
  border-color: var(--color-warning);
}

@media (min-width: 768px) {
  .avatar .dropdown-menu {
    min-width: 620px;
    max-width: 640px;
  }
}
