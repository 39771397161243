.notifications .error {
  background-color: var(--color-danger);
  border-bottom: 1px solid var(--color-danger-700);
}

.notifications .success {
  background-color: var(--color-success);
  border-bottom: 1px solid var(--color-success-700);
}

.notifications .warning {
  background-color: var(--color-warning);
  border-bottom: 1px solid var(--color-warning-700);
}
