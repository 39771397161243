.tearoff-box {
  border-bottom: 2px dashed var(--color-gray);
  font-size: 12pt;
}

.tearoff-box > div {
  padding: 8mm 5mm;
}

.tearoff-box > div:first-child {
  border-right: 2px dashed var(--color-gray);
}

.tearoff-box .tearoff-url {
  padding-top: 2mm;
}
