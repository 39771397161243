.text-crossed { text-decoration: line-through; }
.text-underline { text-decoration: underline; }
.text-overline { text-decoration: overline; }
.text-noline { text-decoration: none; }

.text-xs { font-size: 0.8rem; }
.text-sm { font-size: 0.9rem; }
.text-base { font-size: 1rem; }
.text-lg { font-size: 1.125rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }
.text-3xl { font-size: 1.875rem; }
.text-4xl { font-size: 2rem; }

.font-light { font-weight: 300; }
.font-normal { font-weight: 400; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }

.text-lowercase { text-transform: lowercase; }
.text-uppercase { text-transform: uppercase; }
.text-capitalize { text-transform: capitalize; }
.text-nocase { text-transform: none; }

.text-primary { color: var(--color-primary) !important; }
.text-primary-100 { color: var(--color-primary-100) !important; }
.text-primary-200 { color: var(--color-primary-200) !important; }
.text-primary-300 { color: var(--color-primary-300) !important; }
.text-primary-400 { color: var(--color-primary-400) !important; }
.text-primary-500 { color: var(--color-primary-500) !important; }
.text-primary-600 { color: var(--color-primary-600) !important; }
.text-primary-700 { color: var(--color-primary-700) !important; }
.text-primary-800 { color: var(--color-primary-800) !important; }
.text-primary-900 { color: var(--color-primary-900) !important; }
.text-danger { color: var(--color-danger) !important; }
.text-danger-100 { color: var(--color-danger-100) !important; }
.text-danger-200 { color: var(--color-danger-200) !important; }
.text-danger-300 { color: var(--color-danger-300) !important; }
.text-danger-400 { color: var(--color-danger-400) !important; }
.text-danger-500 { color: var(--color-danger-500) !important; }
.text-danger-600 { color: var(--color-danger-600) !important; }
.text-danger-700 { color: var(--color-danger-700) !important; }
.text-danger-800 { color: var(--color-danger-800) !important; }
.text-danger-900 { color: var(--color-danger-900) !important; }
.text-info { color: var(--color-info) !important; }
.text-info-100 { color: var(--color-info-100) !important; }
.text-info-200 { color: var(--color-info-200) !important; }
.text-info-300 { color: var(--color-info-300) !important; }
.text-info-400 { color: var(--color-info-400) !important; }
.text-info-500 { color: var(--color-info-500) !important; }
.text-info-600 { color: var(--color-info-600) !important; }
.text-info-700 { color: var(--color-info-700) !important; }
.text-info-800 { color: var(--color-info-800) !important; }
.text-info-900 { color: var(--color-info-900) !important; }
.text-warning { color: var(--color-warning) !important; }
.text-warning-100 { color: var(--color-warning-100) !important; }
.text-warning-200 { color: var(--color-warning-200) !important; }
.text-warning-300 { color: var(--color-warning-300) !important; }
.text-warning-400 { color: var(--color-warning-400) !important; }
.text-warning-500 { color: var(--color-warning-500) !important; }
.text-warning-600 { color: var(--color-warning-600) !important; }
.text-warning-700 { color: var(--color-warning-700) !important; }
.text-warning-800 { color: var(--color-warning-800) !important; }
.text-warning-900 { color: var(--color-warning-900) !important; }
.text-success { color: var(--color-success) !important; }
.text-success-100 { color: var(--color-success-100) !important; }
.text-success-200 { color: var(--color-success-200) !important; }
.text-success-300 { color: var(--color-success-300) !important; }
.text-success-400 { color: var(--color-success-400) !important; }
.text-success-500 { color: var(--color-success-500) !important; }
.text-success-600 { color: var(--color-success-600) !important; }
.text-success-700 { color: var(--color-success-700) !important; }
.text-success-800 { color: var(--color-success-800) !important; }
.text-success-900 { color: var(--color-success-900) !important; }
.text-gray { color: var(--color-gray) !important; }
.text-gray-100 { color: var(--color-gray-100) !important; }
.text-gray-200 { color: var(--color-gray-200) !important; }
.text-gray-300 { color: var(--color-gray-300) !important; }
.text-gray-400 { color: var(--color-gray-400) !important; }
.text-gray-500 { color: var(--color-gray-500) !important; }
.text-gray-600 { color: var(--color-gray-600) !important; }
.text-gray-700 { color: var(--color-gray-700) !important; }
.text-gray-800 { color: var(--color-gray-800) !important; }
.text-gray-900 { color: var(--color-gray-900) !important; }
.text-white { color: var(--color-white) !important; }
.text-black { color: var(--color-black) !important; }
