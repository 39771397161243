.label {
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}

.label.label-primary,
.label.label-success,
.label.label-default,
.label.label-info,
.label.label-warning,
.label.label-danger {
  background-color: transparent;
}

.label.label-primary {
  color: var(--color-primary);
}

.label.label-success {
  color: var(--color-success);
}

.label.label-danger {
  color: var(--color-danger);
}

.label.label-info {
  color: var(--color-info);
}

.label.label-default {
  color: var(--color-gray);
}

.label.label-warning {
  color: var(--color-warning);
}

.badge {
  font-size: 0.8rem;
}

.badge.badge-primary {
  background-color: var(--color-primary);
}

.badge.badge-success {
  background-color: var(--color-success);
}

.badge.badge-danger {
  background-color: var(--color-danger);
}

.badge.badge-info {
  background-color: var(--color-info);
}

.badge.badge-default {
  background-color: var(--color-gray);
}

.badge.badge-warning {
  background-color: var(--color-warning);
}

.label.label-bg {
  background-color: var(--color-gray-200);
  border-radius: 15px;
  color: var(--color-gray-500);
  font-size: 0.875rem;
  margin-right: 10px;
  padding: 4px 15px;
  text-transform: none;
}

.label.label-bg > .close {
  font-size: 0.875rem;
}

.sync-state-new,
.sync-state-synced,
.sync-state-error {
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 300;
  padding: 0;
  text-transform: uppercase;
}

.sync-state-new {
  background-color: var(--color-primary);
}

.sync-state-new[href]:hover,
.sync-state-new[href]:focus {
  color: var(--color-primary-800);
}

.sync-state-synced {
  background-color: var(--color-success);
}

.sync-state-synced[href]:hover,
.sync-state-synced[href]:focus {
  color: var(--color-success-800);
}

.sync-state-error {
  background-color: var(--color-danger);
}

.sync-state-error[href]:hover,
.sync-state-error[href]:focus {
  color: var(--color-danger-800);
}

.tag {
  border-radius: var(--button-border-radius-lg);
  font-size: 0.875rem;
  padding-right: 7px;
}

.tag:hover,
.tag:focus,
.tag:active {
  cursor: pointer;
  text-decoration: none;
}

.tag .tag-name {
  background-color: var(--color-gray-200);
  border-bottom-left-radius: var(--button-border-radius-lg);
  border-top-left-radius: var(--button-border-radius-lg);
  color: var(--color-gray);
  margin-right: -4px;
  padding: 3px 6px 3px 12px;
}

.tag:hover .tag-name,
.tag:focus .tag-name,
.tag:active .tag-name {
  background-color: var(--color-gray-300);
}

.tag .tag-count {
  background-color: var(--color-primary);
  border-bottom-right-radius: var(--button-border-radius-lg);
  border-top-right-radius: var(--button-border-radius-lg);
  color: var(--color-white);
  padding: 3px 9px;
}

.tag.tag-no-count .tag-name {
  border-bottom-right-radius: var(--button-border-radius-lg);
  border-top-right-radius: var(--button-border-radius-lg);
  margin-right: 0;
  padding-right: 12px;
}

.label-color-dot {
  background-color: var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.label-color-dot.label-color-dot-sm {
  height: 9px;
  width: 9px;
}

.label-warning-dot {
  background-color: var(--color-warning);
}

.label-success-dot {
  background-color: var(--color-success);
}
