#login-container {
  margin-top: 70px;
}

.flexbox .login #header-menu .container-fluid {
  display: flex;
}

.flexbox .login #header-menu .container-fluid #navbar {
  flex: 1;
}

.flexbox .login #header-menu .container-fluid #navbar .navbar-nav {
  float: none;
}

.login #login-container li,
.login #header-menu .navbar-nav li {
  display: block;
}

@media (min-width: 480px) {
  #login-container {
    margin-top: 140px;
  }
}
