.icon-big {
  font-size: 26px;
}

.centered-icon {
  padding-top: 10px;
  text-align: center;
}

.attached-documents-list {
  margin: 0;
  padding: 0;
}

.attached-documents-list > li {
  background-color: var(--color-gray-400);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
  display: block;
  list-style: none inside none;
  margin: 5px 0;
  padding: 10px;
  width: 100%;
}

.centered-icon,
.centered-buttons {
  padding-top: 7px;
}

@media (min-width: 768px) {
  .attached-documents-list {
    display: inline-block;
    margin: 5px 3% 5px 0;
    width: 30.33%;
  }

  .attached-documents-list:last-child {
    margin-right: 0;
  }
}
