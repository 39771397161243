/* stylelint-disable custom-property-empty-line-before */
:root {
  /* Breakpoints */
  --screen-xs: 480px;
  --screen-sm: 768px;
  --screen-sm-min: var(--screen-sm);
  --screen-md: 992px;
  --screen-md-min: var(--screen-md);
  --screen-lg: 1200px;
  --screen-lg-min: var(--screen-lg);
  --screen-xs-max: calc(var(--screen-sm-min) - 1);
  --screen-sm-max: calc(var(--screen-md-min) - 1);
  --screen-md-max: calc(var(--screen-lg-min) - 1);

  --rem-base: 1rem;
  --base-font-size: 88.88%;
  --size-gutter: 16px;
  --size-increment-step: 0.25em;

  --body-font-weight: 300;
  --body-font-style: normal;
  --base-line-height: 140%;
  --cursor-default-value: 'default';

  --heading-font-family: 'DM Sans', sans-serif;
  --body-font-family: 'DM Sans', sans-serif;

  --button-border-radius: 8px;
  --button-border-radius-sm: 4px;
  --button-border-radius-lg: 16px;
  --avatar-border-radius: 50%;

  --border-radius: 0;
  --border-color: var(--color-gray);
  --border-style-dotted: 1px dashed var(--color-info);
  --border-style-default: 1px solid var(--color-info);

  --line-height-base: 1.428571429;
  --padding-base-vertical: 6px;
  --padding-base-horizontal: 12px;
  --line-height-computed: calc(var(--rem-base) * var(--line-height-base));
  --input-height-base: calc(var(--line-height-computed) + (var(--padding-base-vertical) * 2) + 2);

  /* risr colors */
  --rsr-color-primary: #1f5b73;
  --rsr-color-primary-100: #ebf5fa;
  --rsr-color-primary-200: #c3e2ef;
  --rsr-color-primary-300: #9bcfe4;
  --rsr-color-primary-400: #72bcd9;
  --rsr-color-primary-500: #4aa9ce;
  --rsr-color-primary-600: #318fb5;
  --rsr-color-primary-700: #266f8d;
  --rsr-color-primary-800: #1b4f64;
  --rsr-color-primary-900: #10303c;
  --rsr-color-info: #001e33;
  --rsr-color-info-100: #e5f4ff;
  --rsr-color-info-200: #b3e0ff;
  --rsr-color-info-300: #80cbff;
  --rsr-color-info-400: #4db5ff;
  --rsr-color-info-500: #1aa1ff;
  --rsr-color-info-600: #0087e6;
  --rsr-color-info-700: #0069b3;
  --rsr-color-info-800: #004b80;
  --rsr-color-info-900: #002d4d;
  --rsr-color-danger: #d00000;
  --rsr-color-danger-100: #ffe5e5;
  --rsr-color-danger-200: #ffb3b3;
  --rsr-color-danger-300: #ff8080;
  --rsr-color-danger-400: #ff4d4d;
  --rsr-color-danger-500: #ff1a1a;
  --rsr-color-danger-600: #e60000;
  --rsr-color-danger-700: #b30000;
  --rsr-color-danger-800: #800000;
  --rsr-color-danger-900: #4d0000;
  --rsr-color-success: #105547;
  --rsr-color-success-100: #eafbf7;
  --rsr-color-success-200: #bff3e8;
  --rsr-color-success-300: #94ebd9;
  --rsr-color-success-400: #69e3ca;
  --rsr-color-success-500: #3edbbb;
  --rsr-color-success-600: #24c1a1;
  --rsr-color-success-700: #2a7d6e;
  --rsr-color-success-800: #32695e;
  --rsr-color-success-900: #0c4036;
  --rsr-color-warning: #ff6d00;
  --rsr-color-warning-100: #fff0e5;
  --rsr-color-warning-200: #ffd3b3;
  --rsr-color-warning-300: #ffb680;
  --rsr-color-warning-400: #ff994d;
  --rsr-color-warning-500: #ff7c1a;
  --rsr-color-warning-600: #e66200;
  --rsr-color-warning-700: #b34c00;
  --rsr-color-warning-800: #803700;
  --rsr-color-warning-900: #4d2100;
  --rsr-color-neutral: #4f4f50;
  --rsr-color-neutral-100: #f2f2f2;
  --rsr-color-neutral-200: #d9d9d9;
  --rsr-color-neutral-300: #bfbfc0;
  --rsr-color-neutral-400: #a5a5a6;
  --rsr-color-neutral-500: #8c8c8d;
  --rsr-color-neutral-600: #727273;
  --rsr-color-neutral-700: #59595a;
  --rsr-color-neutral-800: #3f3f40;
  --rsr-color-neutral-900: #262626;

  /* Colors to match their names in the org settings */
  --color-primary: var(--rsr-color-primary);
  --color-primary-100:var(--rsr-color-primary-100);
  --color-primary-200:var(--rsr-color-primary-200);
  --color-primary-300:var(--rsr-color-primary-300);
  --color-primary-400:var(--rsr-color-primary-400);
  --color-primary-500:var(--rsr-color-primary-500);
  --color-primary-600:var(--rsr-color-primary-600);
  --color-primary-700:var(--rsr-color-primary-700);
  --color-primary-800:var(--rsr-color-primary-800);
  --color-primary-900:var(--rsr-color-primary-900);
  --color-info: var(--rsr-color-info);
  --color-info-100:var(--rsr-color-info-100);
  --color-info-200:var(--rsr-color-info-200);
  --color-info-300:var(--rsr-color-info-300);
  --color-info-400:var(--rsr-color-info-400);
  --color-info-500:var(--rsr-color-info-500);
  --color-info-600:var(--rsr-color-info-600);
  --color-info-700:var(--rsr-color-info-700);
  --color-info-800:var(--rsr-color-info-800);
  --color-info-900:var(--rsr-color-info-900);
  --color-danger: var(--rsr-color-danger);
  --color-danger-100:var(--rsr-color-danger-100);
  --color-danger-200:var(--rsr-color-danger-200);
  --color-danger-300:var(--rsr-color-danger-300);
  --color-danger-400:var(--rsr-color-danger-400);
  --color-danger-500:var(--rsr-color-danger-500);
  --color-danger-600:var(--rsr-color-danger-600);
  --color-danger-700:var(--rsr-color-danger-700);
  --color-danger-800:var(--rsr-color-danger-800);
  --color-danger-900:var(--rsr-color-danger-900);
  --color-success: var(--rsr-color-success);
  --color-success-100:var(--rsr-color-success-100);
  --color-success-200:var(--rsr-color-success-200);
  --color-success-300:var(--rsr-color-success-300);
  --color-success-400:var(--rsr-color-success-400);
  --color-success-500:var(--rsr-color-success-500);
  --color-success-600:var(--rsr-color-success-600);
  --color-success-700:var(--rsr-color-success-700);
  --color-success-800:var(--rsr-color-success-800);
  --color-success-900:var(--rsr-color-success-900);
  --color-warning: var(--rsr-color-warning);
  --color-warning-100:var(--rsr-color-warning-100);
  --color-warning-200:var(--rsr-color-warning-200);
  --color-warning-300:var(--rsr-color-warning-300);
  --color-warning-400:var(--rsr-color-warning-400);
  --color-warning-500:var(--rsr-color-warning-500);
  --color-warning-600:var(--rsr-color-warning-600);
  --color-warning-700:var(--rsr-color-warning-700);
  --color-warning-800:var(--rsr-color-warning-800);
  --color-warning-900:var(--rsr-color-warning-900);
  --color-gray: var(--rsr-color-neutral);
  --color-gray-100: var(--rsr-color-neutral-100);
  --color-gray-200: var(--rsr-color-neutral-200);
  --color-gray-300: var(--rsr-color-neutral-300);
  --color-gray-400: var(--rsr-color-neutral-400);
  --color-gray-500: var(--rsr-color-neutral-500);
  --color-gray-600: var(--rsr-color-neutral-600);
  --color-gray-700: var(--rsr-color-neutral-700);
  --color-gray-800: var(--rsr-color-neutral-800);
  --color-gray-900: var(--rsr-color-neutral-900);

  --color-banner-header: var(--rsr-color-primary);

  --color-white: #fff;
  --color-black: #171717;

  --color-form-field-focus: hsla(65, 100%, 87%, 0.64);
  --color-box-shadow: hsla(0, 0%, 0%, 0.12);

  --body-background: hsl(0, 0%, 90%);
  --font-color: var(--color-gray-900);
  --font-hover-color: var(--color-gray-700);
  --theme-button-font-color: hsl(0, 0%, 100%);
  --link-color: var(--color-primary-700);
}
/* stylelint-enable custom-property-empty-line-before */
