.kz-pagination {
  border-bottom: 1px solid var(--color-gray-200);
}

.kz-pagination ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.kz-pagination li {
  display: inline-block;
  padding: 0.8em 0.4em;
  text-align: center;
}

.kz-pagination > div:first-child li {
  border-right: 1px solid var(--color-gray-200);
}

.kz-pagination > div:last-child li {
  border-left: 1px solid var(--color-gray-200);
}

.kz-pagination > div:first-child li:first-child {
  margin-left: -15px;
}

.kz-pagination > div:last-child li:last-child {
  margin-right: -15px;
}
