.nav-tabs {
  background-color: var(--color-info-700);
  border-bottom: 0;
  margin: 20px 0;
}

.nav-tabs li {
  border-bottom: 4px solid transparent;
}

.nav-tabs li.active {
  border-bottom-color: var(--color-info-200);
}

.nav-tabs > li > a {
  border: 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  color: var(--font-color);
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-primary);
  cursor: pointer;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: transparent;
  border: 0;
  border-bottom-color: var(--color-primary);
  color: var(--color-primary);
}

.tab-content {
  margin-top: 20px;
}
