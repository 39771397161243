/* Variables */
.facetly-facets,
.facetly-filters, .facetly-typeahead-suggestions ul, .facetly-typeahead-selected ul {
  list-style: none inside none;
  margin: 0;
  padding: 0; }

.facetly-button {
  background-image: none;
  border-radius: var(--button-border-radius-sm);
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  padding: 8px 25px;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  .facetly-button:hover, .facetly-button:active, .facetly-button:focus {
    text-decoration: none; }

.facetly-filters > li, .facetly-facet {
  background-color: var(--color-gray-200);
  border-radius: var(--button-border-radius-sm);
  padding: 4px; }

.facetly-button.facetly-button-primary {
  background-color: var(--color-primary);
  color: var(--color-white); }
  .facetly-button.facetly-button-primary:hover {
    background-color: var(--color-primary-800);
    border-color: var(--color-primary-800); }

.facetly-button.facetly-button-success {
  background-color: var(--color-success);
  color: var(--color-white); }
  .facetly-button.facetly-button-success:hover {
    background-color: var(--color-success-800);
    border-color: var(--color-success-800); }

.facetly-button.facetly-button-danger {
  background-color: var(--color-danger);
  color: var(--color-white); }
  .facetly-button.facetly-button-danger:hover {
    background-color: var(--color-danger-800);
    border-color: var(--color-danger-800); }

.facetly-button.facetly-button-info {
  background-color: var(--color-info);
  color: var(--color-white); }
  .facetly-button.facetly-button-info:hover {
    background-color: var(--color-info-800);
    border-color: var(--color-info-800); }

.facetly-button.facetly-button-warning {
  background-color: var(--color-warning);
  color: var(--color-white); }
  .facetly-button.facetly-button-warning:hover {
    background-color: var(--color-warning-800);
    border-color: var(--color-warning-800); }

.facetly-button.facetly-button-default {
  background-color: var(--color-gray);
  color: var(--color-white); }
  .facetly-button.facetly-button-default:hover {
    background-color: var(--color-gray-800);
    border-color: var(--color-gray-800); }

.facetly-text-primary {
  color: var(--color-primary) !important; }

.facetly-text-success {
  color: var(--color-success) !important; }

.facetly-text-danger {
  color: var(--color-danger) !important; }

.facetly-text-info {
  color: var(--color-info) !important; }

.facetly-text-warning {
  color: var(--color-warning) !important; }

.facetly-text-default {
  color: var(--color-gray) !important; }

.facetly-facets > li,
.facetly-filters > li {
  display: inline-block;
  margin: 4px; }
  .facetly-facets > li:first-child,
  .facetly-filters > li:first-child {
    margin-left: 0; }
  .facetly-facets > li:last-child,
  .facetly-filters > li:last-child {
    margin-right: 0; }

.facetly-filters {
  display: inline-block; }
  .facetly-filters > li {
    border: 1px solid transparent; }
    .facetly-filters > li.facetly-validation-error {
      border-color: var(--color-danger); }
    .facetly-filters > li .facetly-facet {
      margin: 4px; }
    .facetly-filters > li .facetly-value {
      background-color: var(--color-white);
      border-radius: var(--button-border-radius-sm);
      padding: 0 6px; }

.facetly-facet {
  padding-left: 8px;
  padding-right: 8px;
  text-transform: uppercase; }

a.facetly-facet:active, a.facetly-facet:focus, a.facetly-facet:hover {
  background-color: var(--color-gray-300); }

.facetly-facet,
.facetly-facet a {
  color: var(--color-gray);
  text-decoration: none; }

.facetly-searchbox {
  background-color: transparent;
  background-image: linear-gradient(var(--color-primary), var(--color-primary)), linear-gradient(var(--color-gray), var(--color-gray));
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  display: inline-block;
  float: none;
  font-size: 14px;
  padding: 5px 0;
  transition: background 0s ease-out;
  width: 80%; }
  .facetly-searchbox:focus {
    outline: none; }

.facetly-buttons {
  width: 20%; }

.facetly-searchbox,
.facetly-buttons {
  float: left; }

.facetly-search:before, .facetly-search:after {
  content: " ";
  display: table; }

.facetly-search:after {
  clear: both; }

.facetly-typeahead {
  display: inline-block; }

.facetly-typeahead-suggestions {
  background: var(--color-gray-100);
  border: 1px solid var(--color-gray-300);
  box-shadow: 3px 4px 5px -2px var(--color-gray-200);
  cursor: pointer;
  max-width: 600px;
  min-width: 160px;
  opacity: 90;
  position: absolute;
  z-index: 1000; }
  .facetly-typeahead-suggestions ul {
    max-height: 100px;
    overflow-y: scroll; }
    .facetly-typeahead-suggestions ul li {
      border-bottom: 1px solid var(--color-gray-200);
      margin: 5px 0; }
      .facetly-typeahead-suggestions ul li.facetly-suggestion:hover, .facetly-typeahead-suggestions ul li.facetly-suggestion.selected {
        background-color: var(--color-info); }
        .facetly-typeahead-suggestions ul li.facetly-suggestion:hover a, .facetly-typeahead-suggestions ul li.facetly-suggestion.selected a {
          color: var(--color-white); }
      .facetly-typeahead-suggestions ul li.last {
        border-bottom: 0; }
      .facetly-typeahead-suggestions ul li a,
      .facetly-typeahead-suggestions ul li span {
        color: var(--color-gray);
        padding: 5px;
        text-decoration: none; }
      .facetly-typeahead-suggestions ul li a:hover,
      .facetly-typeahead-suggestions ul li a:active,
      .facetly-typeahead-suggestions ul li a:focus {
        color: var(--color-white); }
      .facetly-typeahead-suggestions ul li > a,
      .facetly-typeahead-suggestions ul li > span {
        display: block; }

.facetly-typeahead-selected {
  display: inline-block; }
  .facetly-typeahead-selected ul > li {
    display: inline-block;
    margin: 4px; }
    .facetly-typeahead-selected ul > li a {
      text-decoration: none; }

.facetly-form-control {
  background-color: transparent;
  background-image: none;
  border: 0;
  box-shadow: none;
  display: inline-block; }
  .facetly-form-control:focus {
    outline: none; }

.facetly-select,
.facetly-hierarchy {
  display: inline-block; }
