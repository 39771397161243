.contextual-action-buttons {
  display: none;
}

@media (min-width: 992px) {
  .contextual-action-buttons {
    display: inline-block;
  }
}

@media (max-width: 992px) {
  .contextual-action-buttons {
    background: var(--color-white);
    border-radius: 4px;
    bottom: 30%;
    left: 5%;
    padding: 16px;
    position: fixed;
    text-align: center;
    width: 90%;
    z-index: 99999;
  }

  .contextual-action-buttons .action-button {
    border-bottom: 1px solid var(--color-gray-300);
    display: block;
    margin: 0 !important;
    padding: 16px 0;
    width: 100%;
  }

  .contextual-action-buttons .action-button a {
    display: block;
    padding: 8px 0;
  }

  .contextual-action-buttons > .row,
  .contextual-action-buttons > .row > .col-xs-12,
  .contextual-action-buttons > .row > .col-sm-6 {
    clear: both;
    display: inline-block;
    float: none;
    width: 100%;
  }

  .contextual-action-buttons li {
    display: block !important;
  }

  .contextual-action-buttons .action-button:last-child {
    border-bottom-width: 1px;
  }

  .contextual-action-buttons > .row > .col-xs-12:last-child .action-button:last-child {
    border-bottom-width: 0;
  }

  .contextual-action-buttons .action-button .btn {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .contextual-action-buttons .action-button .btn-primary {
    color: var(--color-primary);
  }

  .contextual-action-buttons .action-button .btn-info {
    color: var(--color-info);
  }

  .contextual-action-buttons .action-button .btn-warning {
    color: var(--color-warning);
  }

  .contextual-action-buttons .action-button .btn-danger {
    color: var(--color-danger);
  }

  .contextual-action-buttons .action-button .btn-success {
    color: var(--color-success);
  }
}
