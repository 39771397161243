/* stylelint-disable no-descending-specificity */
#navbar .badge {
  font-size: 0.7125rem;
  margin-top: -5px;
  padding: 5px 7px;
}

#navbar .top-search,
#navbar .icon-md {
  font-size: 1.5rem;
}

#navbar .nav > li,
#navbar .dropdown-menu li {
  font-size: 1.125rem;
  text-align: left;
}

.navbar-default.navbar-fixed-top {
  background-color: var(--color-white);
}

.navbar-default.navbar-fixed-top #navbar .navbar-nav > li a {
  color: var(--font-hover-color);
  font-family: var(--heading-font-family);
}

.navbar-default.navbar-fixed-top #navbar .nav > .active > a,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active > a {
  background-color: transparent;
  color: var(--color-white);
}

.navbar-default.navbar-fixed-top #navbar .navbar-nav > li a:hover,
.navbar-default.navbar-fixed-top #navbar .navbar-nav > li a:focus {
  background-color: transparent;
  color: var(--color-primary);
}

.navbar-default.navbar-fixed-top #navbar .nav > .active,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active {
  background-color: var(--color-primary);
}

.navbar-default.navbar-fixed-top #navbar .nav > .active > a:active,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active > a:active,
.navbar-default.navbar-fixed-top #navbar .nav > .active > a:focus,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active > a:focus,
.navbar-default.navbar-fixed-top #navbar .nav > .active > a:hover,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active > a:hover {
  color: var(--color-white);
}

.navbar-default.navbar-fixed-top #navbar .nav > .active li a,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active li a {
  background-color: transparent;
  color: var(--font-hover-color);
}

.navbar-default.navbar-fixed-top #navbar .nav > .active li a:hover,
.navbar-default.navbar-fixed-top #navbar .nav > .active li a:focus,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active li a:hover,
.navbar-default.navbar-fixed-top #navbar .nav > .active > .dropdown-menu > .active li a:focus {
  color: var(--color-primary);
}

.navbar-default.navbar-fixed-top .navbar-brand {
  font-family: var(--heading-font-family);
}

.navbar-logo {
  align-items: center;
  display: flex;
  /* max-height: 80px; */
}

.navbar-logo > * {
  border-right: none; /* We override this in larger screens */
  padding: 0 0.64em;
}

.navbar-logo > *:last-child {
  border-right: none;
}

#header-menu .navbar-toggle {
  float: none;
}

#header-menu .navbar-nav .dropdown-menu li > a {
  font-size: 1rem;
}

.navbar-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.navbar-collapse.collapse {
  display: none !important;
}

.navbar-collapse.collapse.in {
  display: block !important;
}

.nav > li.avatar {
  padding: 0.6em 0.98em 0.4em 0.98em;
}

.nav li.avatar .open > a {
  background-color: transparent;
  display: inline-block;
}

.nav > li.network-link {
  padding: 1.4em;
}

#header-menu .navbar-nav li,
#remote-header-menu .navbar-nav li {
  display: inline-block;
}

#header-menu .navbar-nav li ul > li,
#remote-header-menu .navbar-nav li ul > li {
  display: block;
}

#header-menu .navbar-collapse .navbar-nav li {
  display: block;
}

.navbar .dropdown-menu li > a,
.navbar.navbar-default .dropdown-menu li > a {
  font-size: 1em;
  padding: 0.4em 1.5em;
}

.navbar-header {
  float: left;
  padding-right: 15px;
}

.navbar-header .navbar-toggle,
.navbar-header [class^="icon-"] {
  font-size: 2.25rem;
  line-height: 2.25em;
}

.navbar-header img {
  max-width: 70px;
  max-height: 40px;
}

.caret {
  border-top: 4px solid;
}

.network-link .dropdown-menu {
  margin: 0;
  min-width: 200px;
  max-width: 320px;
  padding: 0;
}

.network-link .open .dropdown-menu {
  background-color: var(--color-white);
  float: left;
  left: auto;
  position: absolute;
  right: 0;
}

.network-link .dropdown-menu li .btn {
  margin: 0 !important;
}

.network-link .dropdown-menu li > a {
  display: inline-block;
}

.network-link .dropdown-menu li > a.btn-sm {
  font-size: 0.75rem !important;
  padding: 5px 20px;
}
/* stylelint-enable no-descending-specificity */

.logo--desktop {
  display: none;
}

@media screen and (min-width: 480px) {
  .navbar-logo {
    margin: 0;
  }
}

@media (min-width: 768px) {
  .navbar-logo > * {
    border-right: 2px solid var(--color-gray-200);
  }

  #header-menu .navbar-collapse li {
    display: inline-block;
  }

  .network-link .dropdown-menu {
    min-width: 320px;
    max-width: 340px;
  }
}

@media (min-width: 992px) {
  .navbar-collapse.collapse.in {
    display: block !important;
  }
}

@media (max-width: 992px) {
  #header-menu .navbar-logo {
    margin-left: 15px;
  }
}

@media (max-width: 1200px) {
  #navbar.navbar-collapse.collapse {
    display: none !important;
  }

  #navbar.navbar-collapse.collapse.in {
    display: block !important;
  }
}

@media (min-width: 768px) {
  #navbar .divider-left {
    border-left-style: solid;
    border-left-width: 1px;
    margin-left: 20px;
    padding: 0 10px;
  }

  #navbar .divider-right {
    border-right-style: solid;
    border-right-width: 1px;
    margin-right: 20px;
    padding: 0 10px;
  }

  #navbar .nav > li,
  #navbar .dropdown-menu li {
    text-align: left;
  }

  .navbar .navbar-nav > li > a,
  #navbar .nav > li > a {
    padding: 1em;
  }
}

@media (max-width: 768px) {
  .navbar-nav {
    margin: 0 -15px;
  }
}

@media screen and (min-width: 1200px) {
  .logo--desktop {
    display: block;
  }

  .logo--mobile {
    display: none;
  }
}


@media screen and (min-width: 1400px) {
  .navbar-header img {
    max-width: 120px;
  }
}
