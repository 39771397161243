.dropzone {
  background-color: var(--color-gray-200);
  border: 2px dashed var(--color-gray-400);
  min-height: 18px;
  padding: 30px 5px;
}

/* Dropzone spritemap correct path */
.dropzone .dz-default.dz-message {
  background-image: none;
  height: auto;
  left: 0;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  width: 100%;
}

.dropzone .dz-default.dz-message span {
  color: var(--color-gray);
  display: block;
  font-size: 18px;
  text-align: center;
}

@media all and (-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  .dropzone .dz-default.dz-message {
    background-image: none;
  }
}

.dropzone .dz-preview .dz-error-mark,
.dropzone-previews .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark,
.dropzone-previews .dz-preview .dz-success-mark {
  background-image: url('src/assets/images/dropzone.png');
}

@media all and (-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  .dropzone .dz-preview .dz-error-mark,
  .dropzone-previews .dz-preview .dz-error-mark,
  .dropzone .dz-preview .dz-success-mark,
  .dropzone-previews .dz-preview .dz-success-mark {
    background-image: url('src/assets/images/dropzone@2x.png');
  }
}

@media all and (-webkit-min-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 1.5/1), (min-resolution: 138dpi), (min-resolution: 1.5dppx) {
  .dropzone .dz-preview .dz-progress .dz-upload,
  .dropzone-previews .dz-preview .dz-progress .dz-upload {
    background-image: url('src/assets/images/dropzone@2x.png');
  }
}
