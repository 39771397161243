#hamburger-menu {
  position: absolute;
  z-index: 1;
  height: auto;
}

#hamburger-menu #icon-menu {
  padding: 18px;
  position: absolute;
  z-index: 999;
}

#hamburger-menu .ng-hide {
  width: 0;
}

#full-screen-menu {
  background: var(--color-primary) center;
  color: var(--color-white);
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  position: fixed;
  text-align: left;
  transition: all ease-in-out 0.1s;
  width: 100%;
  z-index: 2;
}

#full-screen-menu h2,
#full-screen-menu h3 {
  color: var(--color-white);
}

#full-screen-menu a {
  color: var(--color-white);
  text-decoration: none;
}

#full-screen-menu i {
  font-size: 30px;
  color: var(--color-white);
  margin: 25% auto;
}

#full-screen-menu .menu-row {
  padding: 10px;
}

#full-screen-menu .menu-separator {
  border-bottom: 1px solid var(--color-white);
  margin-left: 5px;
  margin-right: 5px;
}

#full-screen-menu .right-arrow {
  padding-top: 7px;
}

#full-screen-menu .hm-sub-items-text {
  font-size: 0.9rem;
  font-style: italic;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 15px 15px;
  text-transform: none;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
}

.hamburger:hover,
.hamburger:focus {
  opacity: 0.7;
}

.hamburger-box {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 40px;
}

.hamburger-inner {
  display: block;
  margin-top: -2px;
  top: 50%;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: var(--color-black);
  border-radius: 4px;
  height: 4px;
  position: absolute;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 40px;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  opacity: 0;
  top: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (prefers-reduced-motion: reduce) {
  #full-screen-menu {
    transition: none;
  }

  .hamburger--squeeze .hamburger-inner::before {
    transition: none;
  }

  .hamburger--squeeze .hamburger-inner::after {
    transition: none;
  }

  .hamburger--squeeze.is-active .hamburger-inner::before {
    transition: none;
  }

  .hamburger--squeeze.is-active .hamburger-inner::after {
    transition: none;
  }
}
