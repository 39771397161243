.atlwdg-trigger.atlwdg-SUBTLE {
  background: var(--color-gray-100) !important;
  border-radius: 4px 4px 0 0 !important;
  border: 0 !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) !important;
  color: var(--color-gray-600) !important;
  display: none;
  font-family: "Lucida Grande", sans-serif !important;
  font-size: 12px !important;
  font-weight: normal;
  padding: 7px 12px !important;
  right: 20px !important;
}

@media (min-width: 768px) {
  .atlwdg-trigger.atlwdg-SUBTLE {
    display: inline-block;
  }
}
