.calendar .btn {
  border: 0;
  padding: 10px;
}

.calendar .btn.active,
.calendar .btn:active,
.calendar .btn:hover,
.calendar .btn:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: var(--color-primary);
  outline: none;
}

.calendar .btn.active .text-info,
.calendar .btn:active .text-info,
.calendar .btn:hover .text-info,
.calendar .btn:focus .text-info {
  color: var(--color-primary);
}
