.img-signature {
  max-width: 640px;
  width: 100%;
}

@media (min-width: 768px) {
  .img-signature {
    max-width: 320px;
  }
}
