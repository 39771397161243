.audit-log p {
  margin: 3px 0;
}

.audit-log .meta-author {
  font-weight: bold;
}

.audit-log {
  border-top-color: var(--color-gray-200);
}

.audit-log .audit-log-entry {
  border-left: 1px solid var(--color-gray-400);
  padding: 20px 0 20px 20px;
  position: relative;
}

.audit-log .audit-log-entry::before {
  background: var(--color-info);
  border-radius: 0.6em;
  content: '';
  display: block;
  height: 0.6em;
  left: -0.3em;
  line-height: 0.6em;
  position: absolute;
  text-align: center;
  width: 0.6em;
}

.audit-log .audit-log-entry:first-child {
  margin-top: 10px;
  padding-left: 20px;
  padding-top: 0;
}

.audit-log .audit-log-entry:last-child {
  margin-bottom: 10px;
  padding-bottom: 0;
}

.audit-log .audit-log-date,
.audit-log .audit-log-changes li {
  font-size: 0.75rem;
}

.audit-log .audit-log-entry .audit-log-date {
  margin-top: 0;
}
