.placeholder-row {
  border-left: 5px solid var(--color-gray-400);
}

.placeholder-item {
  background: var(--color-white);
  display: block;
  height: 80;
  margin-left: 10px;
  padding: 10px 0;
  width: 80%;
}

.placeholder-item .placeholder-item-animated {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: 5;
  animation-name: contentPlaceholderShimmer;
  animation-timing-function: linear;
  background: var(--color-gray-300) linear-gradient(to right, var(--color-gray-300) 8%, var(--color-gray-400) 18%, var(--color-gray-300) 33%) 100% 90px;
  height: 60px;
  position: relative;
  transform: translateZ(0);
}

.placeholder-item .background-masker {
  background: var(--color-white);
  position: absolute;
}

.placeholder-item .background-masker.header-bottom,
.placeholder-item .background-masker.subheader-bottom {
  height: 10px;
  left: 0;
  right: 0;
  top: 0;
}

.placeholder-item .background-masker.header-right,
.placeholder-item .background-masker.subheader-right {
  height: 30px;
  left: 600px;
  right: 0;
  top: 0;
  width: auto;
}

.placeholder-item .background-masker.header-bottom {
  height: 10px;
  top: 32px;
}

.placeholder-item .background-masker.subheader-right {
  height: 24px;
  left: 200px;
  top: 36px;
}

.placeholder-item .background-masker.subheader-bottom {
  height: 15px;
  top: 25px;
}

.placeholder-item .background-masker.content-top,
.placeholder-item .background-masker.content-first-end {
  left: 0;
  right: 0;
  top: 56px;
}

.placeholder-item .background-masker.content-top {
  height: 15px;
}

.placeholder-item .background-masker.content-first-end {
  height: 15px;
  left: 380px;
  right: 0;
  top: 70px;
  width: auto;
}

@media screen and (prefers-reduced-motion: reduce) {
  .placeholder-item .placeholder-item-animated {
    animation: none;
  }
}
