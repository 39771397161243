.progress {
  background-color: var(--color-gray-200);
  border-radius: 2px;
  box-shadow: none;
  height: 3px;
  margin-bottom: 5px;
}

.kz-progress .kz-progress__progress-stats {
  text-align: right;
}

.inline-progress .progress {
  display: inline-block;
  width: 100px;
}

.kz-progress--inline {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.kz-progress--inline .kz-progress__progress-bar {
  flex: 1 0 0;
}

.kz-progress--inline .kz-progress__progress-bar .progress {
  margin-bottom: 0;
}

.kz-progress--inline .kz-progress__progress-stats {
  flex: 1 0 0;
}

.kz-progress--inline .kz-progress__progress-stats .text-gray {
  margin: 0;
}

.progress-bar-success {
  background-color: var(--color-success);
}
