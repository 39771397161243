.goal a {
  text-decoration: none;
}

.goal h2 {
  font-size: 1.125rem;
}

.goal .goal-inner.goal-no-targets h2,
.goal .goal-inner.goal-no-targets a {
  color: var(--color-gray-400);
}

.goal .goal-inner {
  background-color: var(--color-white);
  box-shadow: 0 0 1px 0 var(--color-box-shadow), 0 0 1px 0 var(--color-box-shadow);
  padding: 2px 30px 2px 35px;
}

.goal .goal-inner .col-right {
  text-align: left;
}

.goal .goal-inner .list-inline.list-unstyled li:last-child {
  padding-right: 0;
}

.goal-group .wrapper-top h5.entry-title {
  margin-top: 7px;
}

.new-goal {
  border-bottom: 5px solid var(--color-gray-400);
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.goal-inner .goal-inner__targets {
  margin-left: 22px;
}

.goal-inner .goal-inner__targets .goal-inner__target {
  padding: 4px 0;
}

@media (min-width: 768px) {
  .goal .goal-inner .col-right {
    text-align: right;
  }
}
