.alert {
  margin: 20px 0;
}

.notifications .notifications-container {
  z-index: 9999;
}

.sweet-overlay {
  background-color: var(--color-box-shadow);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1999;
}

.sweet-alert {
  background-color: var(--color-white);
  border-radius: 5px;
  display: none;
  left: 50%;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  padding: 17px;
  position: fixed;
  text-align: center;
  top: 50%;
  width: 478px;
  z-index: 2000;
}

@media (max-width: 480px) {
  .sweet-alert {
    left: 15px;
    margin-left: 0;
    margin-right: 0;
    right: 15px;
    width: auto;
  }
}

.sweet-alert h2 {
  font-family: var(--body-font-family);
  font-size: 1.5rem;
  position: relative;
  text-align: center;
  text-transform: none;
}

.sweet-alert p {
  line-height: normal;
  margin: 0;
  position: relative;
  text-align: center;
}

.sweet-alert button {
  background-color: var(--color-white) !important;
  border: 1px solid var(--color-primary);
  box-shadow: none;
  color: var(--color-primary);
  cursor: pointer;
  margin: 15px 5px 0 5px;
}

.sweet-alert button::-moz-focus-inner {
  border: 0;
}

.sweet-alert button:hover,
.sweet-alert button:active {
  border-color: var(--color-primary-800);
  color: var(--color-primary-800);
}

/* stylelint-disable */
.sweet-alert button:focus {
  outline: none;
}
/* stylelint-enable */

.sweet-alert button.cancel {
  background-color: transparent;
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
}

.sweet-alert button.cancel:hover,
.sweet-alert button.cancel:active {
  border-color: var(--color-warning-dark);
  color: var(--color-warning-800);
}

/* stylelint-disable */
.sweet-alert button.cancel:focus {
  outline: none;
}
/* stylelint-enable */

.sweet-alert[data-has-cancel-button=false] button {
  box-shadow: none !important;
}

.sweet-alert .icon {
  border-radius: 50%;
  border: 3px solid var(--color-gray);
  box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  position: relative;
  width: 80px;
}

.sweet-alert .icon.error {
  border-color: var(--color-danger);
}

.sweet-alert .icon.error .x-mark {
  display: block;
  position: relative;
}

.sweet-alert .icon.error .line {
  background-color: var(--color-danger);
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  top: 37px;
  width: 47px;
}

.sweet-alert .icon.error .line.left {
  left: 17px;
  transform: rotate(45deg);
}

.sweet-alert .icon.error .line.right {
  transform: rotate(-45deg);
  right: 16px;
}

.sweet-alert .icon.warning {
  border-color: var(--color-warning);
}

.sweet-alert .icon.warning .body {
  background-color: var(--color-warning);
  border-radius: 2px;
  height: 47px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  top: 10px;
  width: 5px;
}

.sweet-alert .icon.warning .dot {
  background-color: var(--color-warning);
  border-radius: 50%;
  bottom: 10px;
  height: 7px;
  left: 50%;
  margin-left: -3px;
  position: absolute;
  width: 7px;
}

.sweet-alert .icon.info {
  border-color: var(--color-primary);
}

.sweet-alert .icon.info::before {
  background-color: var(--color-primary);
  border-radius: 2px;
  bottom: 17px;
  content: "";
  height: 29px;
  left: 50%;
  margin-left: -2px;
  position: absolute;
  width: 5px;
}

.sweet-alert .icon.info::after {
  background-color: var(--color-primary);
  border-radius: 50%;
  content: "";
  height: 7px;
  margin-left: -3px;
  position: absolute;
  top: 19px;
  width: 7px;
}

.sweet-alert .icon.success {
  border-color: var(--color-success);
}

.sweet-alert .icon.success::before,
.sweet-alert .icon.success::after {
  background: var(--color-white);
  border-radius: 50%;
  content: '';
  height: 120px;
  position: absolute;
  transform: rotate(45deg);
  width: 60px;
}

.sweet-alert .icon.success::before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  transform-origin: 60px 60px;
  transform: rotate(-45deg);
}

.sweet-alert .icon.success::after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  transform-origin: 0 60px;
  transform: rotate(-45deg);
}

.sweet-alert .icon.success .placeholder {
  border: 4px solid var(--color-success-100);
  border-radius: 50%;
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}

.sweet-alert .icon.success .fix {
  background-color: var(--color-white);
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}

.sweet-alert .icon.success .line {
  background-color: var(--color-success);
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
}

.sweet-alert .icon.success .line.tip {
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  width: 25px;
}

.sweet-alert .icon.success .line.long {
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  width: 47px;
}

.sweet-alert .icon.custom {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0;
  border: none;
}

/*
 * Animations
 */
@-webkit-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@-moz-keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

@-moz-keyframes hideSweetAlert {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

.showSweetAlert {
  animation: showSweetAlert 0.3s;
}

.hideSweetAlert {
  animation: hideSweetAlert 0.2s;
}

@-webkit-keyframes animateSuccessTip {
  0% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  54% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  70% {
    left: -8px;
    top: 37px;
    width: 50px;
  }

  84% {
    left: 21px;
    top: 48px;
    width: 17px;
  }

  100% {
    left: 14px;
    top: 45px;
    width: 25px;
  }
}

@-moz-keyframes animateSuccessTip {
  0% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  54% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  70% {
    left: -8px;
    top: 37px;
    width: 50px;
  }

  84% {
    left: 21px;
    top: 48px;
    width: 17px;
  }

  100% {
    left: 14px;
    top: 45px;
    width: 25px;
  }
}

@keyframes animateSuccessTip {
  0% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  54% {
    left: 1px;
    top: 19px;
    width: 0;
  }

  70% {
    left: -8px;
    top: 37px;
    width: 50px;
  }

  84% {
    left: 21px;
    top: 48px;
    width: 17px;
  }

  100% {
    left: 14px;
    top: 45px;
    width: 25px;
  }
}

@-webkit-keyframes animateSuccessLong {
  0% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  65% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  84% {
    right: 0;
    top: 35px;
    width: 55px;
  }

  100% {
    right: 8px;
    top: 38px;
    width: 47px;
  }
}

@-moz-keyframes animateSuccessLong {
  0% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  65% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  84% {
    right: 0;
    top: 35px;
    width: 55px;
  }

  100% {
    right: 8px;
    top: 38px;
    width: 47px;
  }
}

@keyframes animateSuccessLong {
  0% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  65% {
    right: 46px;
    top: 54px;
    width: 0;
  }

  84% {
    right: 0;
    top: 35px;
    width: 55px;
  }

  100% {
    right: 8px;
    top: 38px;
    width: 47px;
  }
}

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@-moz-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

.animateSuccessTip {
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  animation: animateSuccessLong 0.75s;
}

.icon.success.animate::after {
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animateErrorIcon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@-moz-keyframes animateErrorIcon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes animateErrorIcon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

.animateErrorIcon {
  animation: animateErrorIcon 0.5s;
}

@-webkit-keyframes animateXMark {
  0% {
    margin-top: 26px;
    opacity: 0;
    transform: scale(0.4);
  }

  50% {
    margin-top: 26px;
    opacity: 0;
    transform: scale(0.4);
  }

  80% {
    margin-top: -6px;
    transform: scale(1.15);
  }

  100% {
    margin-top: 0;
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes animateXMark {
  0% {
    margin-top: 26px;
    opacity: 0;
    transform: scale(0.4);
  }

  50% {
    margin-top: 26px;
    opacity: 0;
    transform: scale(0.4);
  }

  80% {
    margin-top: -6px;
    transform: scale(1.15);
  }

  100% {
    margin-top: 0;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animateXMark {
  0% {
    margin-top: 26px;
    opacity: 0;
    transform: scale(0.4);
  }

  50% {
    margin-top: 26px;
    opacity: 0;
    transform: scale(0.4);
  }

  80% {
    margin-top: -6px;
    transform: scale(1.15);
  }

  100% {
    margin-top: 0;
    opacity: 1;
    transform: scale(1);
  }
}

.animateXMark {
  animation: animateXMark 0.5s;
}

@-webkit-keyframes pulseWarning {
  0% {
    border-color: var(--color-warning-200);
  }

  100% {
    border-color: var(--color-warning-400);
  }
}

@-moz-keyframes pulseWarning {
  0% {
    border-color: var(--color-warning-200);
  }

  100% {
    border-color: var(--color-warning-400);
  }
}

@keyframes pulseWarning {
  0% {
    border-color: var(--color-warning-200);
  }

  100% {
    border-color: var(--color-warning-400);
  }
}

.pulseWarning {
  animation: pulseWarning 0.75s infinite alternate;
}

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: var(--color-warning-200);
  }

  100% {
    background-color: var(--color-warning-400);
  }
}

@-moz-keyframes pulseWarningIns {
  0% {
    background-color: var(--color-warning-200);
  }

  100% {
    background-color: var(--color-warning-400);
  }
}

@keyframes pulseWarningIns {
  0% {
    background-color: var(--color-warning-200);
  }

  100% {
    background-color: var(--color-warning-400);
  }
}

.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate;
}

.fixed-badge {
  background: var(--color-gray-100);
  border-radius: 4px 4px 0 0;
  bottom: 0;
  box-shadow: 0 0 0 1px var(--color-box-shadow);
  color: var(--color-gray-600);
  display: inline-block;
  font-family: "Lucida Grande", sans-serif;
  font-size: 12px;
  padding: 7px;
  position: fixed;
  z-index: 2000;
}

#offline-ui {
  display: none;
  left: 400px;
}

#env-ui {
  display: none;
  left: 600px;
}

#status-ui {
  left: 20px;
  z-index: 3000;
}

#offline-ui .offline-ui-content {
  margin-left: 16px;
}

#offline-ui div:first-child {
  border-radius: 50%;
  bottom: 1px;
  content: " ";
  display: block;
  height: 9px;
  left: 8px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 9px;
}

#offline-ui div:first-child.up {
  background: var(--color-success-500);
}

#offline-ui div:first-child.down {
  background: var(--color-danger-500);
}

#offline-ui div:first-child.progress {
  background: var(--color-warning-500);
}

@media screen and (prefers-reduced-motion: reduce) {
  .icon.success.animate::after {
    animation: none;
  }

  .showSweetAlert {
    animation: none;
  }

  .hideSweetAlert {
    animation: none;
  }

  .animateSuccessTip {
    animation: none;
  }

  .animateSuccessLong {
    animation: none;
  }

  .icon {
    animation: none;
  }

  .animateErrorIcon {
    animation: none;
  }

  .animateXMark {
    animation: none;
  }

  .pulseWarning {
    animation: none;
  }

  .pulseWarningIns {
    animation: none;
  }
}
