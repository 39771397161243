/*
<link rel="stylesheet" href="../bower_components/angular-color-picker/dist/angularjs-color-picker.css" />
<link rel="stylesheet" href="../bower_components/bootstrap-daterangepicker/daterangepicker.css" />
<link rel="stylesheet" href="../bower_components/angular-object-diff/dist/angular-object-diff.css" />
*/

@import 'dropzone/downloads/css/dropzone.css';
@import 'toastr/build/toastr.css';
@import 'sweetalert/lib/sweet-alert.css';
@import 'angular-ui-tree/dist/angular-ui-tree.min.css';
@import 'angular-loading-bar/build/loading-bar.css';
@import 'ui-select/dist/select.css';
@import 'angular-dashboard-framework/dist/angular-dashboard-framework.css';
@import 'angular-wizard/dist/angular-wizard.min.css';
@import 'angular-aside/dist/css/angular-aside.css';
@import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
@import 'quill/dist/quill.snow.css';

@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* Not sure this one is used uncomment it if that's the case */
/*@import 'angular-object-diff/dist/angular-object-diff.css';*/


@import './vendor/bootstrap.css';
@import './vendor/bootstrap-md.css';
@import './vendor/angular-sequoia.css';
@import './vendor/angular-facetly.css';
@import './vendor/quill.css';
