.search-bar a:hover,
.search-bar a:active,
.search-bar a:focus {
  color: var(--color-primary-700);
  text-decoration: none;
}

.search-bar.active {
  border-bottom-width: 3px !important;
}

.search-bar .current-filters {
  margin-top: 30px;
  margin-bottom: 10px;
}
