.readonly .ql-size-small {
  font-size: 0.75em;
}
.readonly .ql-size-large {
  font-size: 1.5em;
}
.readonly .ql-size-huge {
  font-size: 2.5em;
}

.readonly img {
  max-width: 100%;
}

.readonly .ql-align-center {
  text-align: center;
}

.readonly .ql-align-left {
  text-align: left;
}

.readonly .ql-align-right {
  text-align: right;
}

.readonly .ql-align-justify {
  text-align: justify;
}
