.kz-skip-navigation {
  font-size: 0.88em;
  left: -9999px;
  position: absolute;
  top: 0;
  z-index: 9999;
}

.kz-skip-navigation:link,
.kz-skip-navigation:visited {
  color: var(--color-gray-800);
  font-weight: bold;
  text-decoration: none;
}

.kz-skip-navigation:active,
.kz-skip-navigation:focus {
  background-color: var(--color-gray-800);
  color: var(--color-white);
  display: inline-block;
  left: 16px;
  padding: 0.8em 1.6em;
}
