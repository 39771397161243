.inline {
  display: inline;
}

.angular-ui-tree-empty {
  display: none;
}

.map-panel {
  max-height: 15em;
  min-height: 15em;
  overflow: auto;
}

.selected {
  background-color: var(--color-gray-100);
}
