.field-properties {
  border: 3px solid var(--color-gray);
  border-radius: 3px;
}

.field-border {
  border-bottom: 1px solid var(--color-gray-400);
  padding-bottom: 10px;
  padding-top: 10px;
}

.field-border:last-child {
  border-bottom-style: none;
}

.ui-select-match-item.btn.btn-default {
  background-color: var(--color-gray-600) !important;
  color: var(--color-gray-100);
}

.ui-select-match-item.btn.btn-default:hover,
.ui-select-match-item.btn.btn-default:focus {
  background-color: var(--color-gray-600) !important;
  color: var(--color-gray-100);
  box-shadow: none !important;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  line-height: 0.8em;
}

.ui-select-match-close {
  color: var(--color-gray-100);
  text-shadow: none;
  opacity: 1;
}

.form-group.likert label,
.form-group.likert input {
  display: block;
}

.color-picker-wrapper.color-picker-swatch-only .input-group .input-group-addon {
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.color-picker-wrapper .color-picker-input-swatch {
  width: 100px;
}
